import React ,{Component} from 'react';
import thankyou from './images/thankyou.png';
export default class Feedbackthankyou extends Component {
	constructor(props) {
		super();
	
	this.state = { 
	
	};
	}
	render(){
		
	return (
	<div className="containermain">
		<div className="containerbg">
	<div className="thankyou">
	<img src={thankyou}/>
	<h2> Thank you for your valuable feedback</h2> 
	</div>
		</div>
			</div>
	)};
}