import React ,{Component} from 'react';
import Button from '@mui/material/Button';
import axios from 'axios';
import Serverurl from './Apiurl';
import { makeStyles } from '@mui/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Navigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,DatePicker, 
  KeyboardDatePicker,
  DateTimePicker
} from '@material-ui/pickers';
import { addDays } from "date-fns";
import Moment from 'react-moment';
import moment from 'moment';


const useStyles = makeStyles(theme => ({  
 
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default class Feedback extends Component {
	
									constructor(props) {
														super();
	 var strdob=new Date();
	 var month="";	
	 if((strdob.getMonth() + 1)<10)
	 {
		month="0"+ (strdob.getMonth() + 1)		 
	 }
else{month=(strdob.getMonth() + 1)}
														this.state = { cname:'', 
																		date:'', 
																		venue:'',																		 
																		fb_id:'',vetype:0,
																		fdate:Date.now(),fdate1:strdob.getFullYear() + '-' + month + '-' +strdob.getDate(),staffarray:[],staff:'',rolelabel:'Select Staff',vendorarray:[],vendor:'',rolelabel:'Select Vendor',decpackagearray:[],decpackage:'',rolelabel:'Select Package',formtypes:'1',venuerating:0,servicerating:0,cleanlinessrating:0,supervisorrating:0,staffrating:0,roomrating:0,overallrating:0,deccomrating:0,decexectimerating:0,decoutputrating:0,decqualityrating:0,decvalmoneyrating:0,decexperrating:0,catcomrating:0,catexectimerating:0,cattasterating:0,catqualityrating:0,catvalmoneyrating:0,catexperrating:0,vetype:'0',erropen:false,
																		photocomrating:0,photoexectimerating:0,photoprofesrating:0,photocrativityrating:0,photovalmoneyrating:0,photoexperrating:0,macomrating:0,maexectimerating:0,maprofesrating:0,macrativityrating:0,mavalmoneyrating:0,maexperrating:0,mapackage:'',photopackage:'',catpackage:'',catvendor:'',secondarytext:'Finish',otherfeedback:'',decotherfeedback:'',catotherfeedback:'',photootherfeedback:'',maotherfeedback:'',otherfeedback:'',activeStep:0,active:'',setactiveStep:0

																		};
													}

		handleback1 = (e) => {
			
				this.setState({formtypes:"1",vetype:"0", activeStep: this.state.activeStep -1});
			
				
		};
		handleback2 = (e) => {
			
				this.setState({formtypes:"2",vetype:"1",activeStep: this.state.activeStep -1});
				this.onloadvendorbind();
				this.onloadpackagebind();
		};
		handleback3 = (e) => {
    
	 
    this.setState({
        formtypes: "3",
        activeStep: this.state.activeStep - 1
    });
localStorage.setItem("vetype", 1);
this.onloadvendorbind();
				this.onloadpackagebind();
   
};

		handleback4 = (e) => {
		
				this.setState({formtypes:"4",vetype:"3",activeStep: this.state.activeStep -1});
			    localStorage.setItem("vetype", 2);
                this.onloadvendorbind();
				this.onloadpackagebind();
				
		};
		 handleback5 = (e) => {
			
				this.setState({formtypes:"5",activeStep:this.state.activeStep -1});
				localStorage.setItem("vetype", 3);
                this.onloadvendorbind();
				this.onloadpackagebind();
				
		};

		getParameterByName(name, url) {
			
				if (!url) url = window.location.href;
				url = url.toLowerCase(); 
				name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
				var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
					results = regex.exec(url);
				if (!results) return null;
				if (!results[2]) return '';
				return decodeURIComponent(results[2].replace(/\+/g, " "));
		}
		handfrChanges = (date) => {
	
	  var month="";
	 
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}   
	 
     this.setState({ fdate:date,
fdate1:date.getFullYear() + '-' + month + '-' +date.getDate()
	 })
	
	
  } 
		handleNext = (e) => {

				 let name=this.state.cname;
				 localStorage.setItem("name", name);
				 let fdate1=this.state.fdate1;
				 localStorage.setItem("fdate1", fdate1);
				 let venue=this.state.venue;
				 localStorage.setItem("venue", venue);
				 let feedbackby=this.state.cuname;
				 localStorage.setItem("feedbackby", feedbackby);
				 let staff=this.state.staff;
				 localStorage.setItem("staff", staff);
			


if(name==="")
		{			
			this.setState({errormessage:"Please Enter Customer Name",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}	
			if(fdate1 === " ")
		{			
			this.setState({errormessage:"Please Select Date",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
			if(venue==="")
		{			
			this.setState({errormessage:"Please Select Venue",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
			
			if(feedbackby==="")
		{			
			this.setState({errormessage:"Please Enter Feedback by",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
			if(staff==="")
		{			
			this.setState({errormessage:"Please Select Feedback Taken By",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}

			this.setState({formtypes:"2",activeStep: this.state.activeStep+1});
			this.setState({erropen: false}); 
		};

	componentDidMount() {  
	if(localStorage.getItem("YSuserid")==null)
			{

				   this.setState({
					redirect: true,
					pagename:'/Login'
				  })
			}		
		
			
		this.onloadstaffbind();
	}

	


	myChangeHandler = (event) => {
		let nam = event.target.name;
		let val = event.target.value;
		this.setState({[nam]: val});
	  
	}
	decmyChangeHandler = (event) => {
		let nam = event.target.name;
		let val = event.target.value;
		this.setState({[nam]: val});
	  
	}
	catmyChangeHandler = (event) => {
		let nam = event.target.name;
		let val = event.target.value;
		this.setState({[nam]: val});
	  
	}
	catsmyChangeHandler = (event) => {
		let nam = event.target.name;
		let val = event.target.value;
		this.setState({[nam]: val});
	  
	}
	photomyChangeHandler = (event) => {
		let nam = event.target.name;
		let val = event.target.value;
		this.setState({[nam]: val});
	  
	}
	photosmyChangeHandler = (event) => {
		let nam = event.target.name;
		let val = event.target.value;
		this.setState({[nam]: val});
	  
	}
	mamyChangeHandler = (event) => {
		let nam = event.target.name;
		let val = event.target.value;
		this.setState({[nam]: val});
	  
	}
	masmyChangeHandler = (event) => {
		let nam = event.target.name;
		let val = event.target.value;
		this.setState({[nam]: val});
	  
	}
	
onloadstaffbind(){

    axios.get(Serverurl+'ajaxstaff.php?action=getall')
	
    
      .then((response) => {    
     
      this.setState({  staffarray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.staffarray.map(staff => {
                            
          return {value: staff.st_id, display:staff.st_name}
        });
      
		
        this.setState({
          staffarray: [{value: '', display: 'Select Staff Name'}].concat(roleFromApi)
        });
      })


} 
onloadvendorbind(){
let vetype = localStorage.getItem("vetype");
    axios.get(Serverurl+'ajaxvendor.php?action=getall&ve_type='+vetype)
	
    
      .then((response) => {    
     
      this.setState({  vendorarray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.vendorarray.map(vendor => {
                            
          return {value: vendor.ve_id, display:vendor.ve_name}
        });
      
		
        this.setState({
          vendorarray: [{value: '', display: 'Select Vendor'}].concat(roleFromApi)
        });
      })


} 


onloadpackagebind(){

    axios.get(Serverurl+'ajaxpackage.php?action=getall&pa_type='+this.state.ve_type)
	
    
      .then((response) => {    
     
      this.setState({  decpackagearray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.decpackagearray.map(decpackage => {
                            
          return {value: decpackage.pa_id, display:decpackage.pa_name}
        });
      
		
        this.setState({
          decpackagearray: [{value: '', display: 'Select Package'}].concat(roleFromApi)
        });
      })


} 


onclick(val){
      
      this.setState({venuerating: val });
    }
	onclick1(val){
   
      this.setState({servicerating: val });
	  
    }
	onclick2(val){
 
      this.setState({cleanlinessrating: val });
    }
	onclick3(val){
      
      this.setState({supervisorrating: val });
    }
	onclick4(val){
    
      this.setState({staffrating: val });
    }
	onclick5(val){
    
      this.setState({roomrating: val });
    }
	onclick6(val){
     
      this.setState({overallrating: val });
    }
	
deconclick(val){
    
      this.setState({deccomrating: val });
    }
	deconclick1(val){
   
      this.setState({decexectimerating: val });
    }
	deconclick2(val){
      
      this.setState({decoutputrating: val });
    }
	deconclick3(val){
      
      this.setState({decqualityrating: val });
    }
	deconclick4(val){
      
      this.setState({decvalmoneyrating: val });
    }
	deconclick5(val){
     
      this.setState({decexperrating: val });
    }	
catonclick(val){
     
      this.setState({catcomrating: val });
    }
	catonclick1(val){
     
      this.setState({catexectimerating: val });
    }
	catonclick2(val){
      
      this.setState({cattasterating: val });
    }
	catonclick3(val){
     
      this.setState({catqualityrating: val });
    }
	catonclick4(val){
     
      this.setState({catvalmoneyrating: val });
    }
	catonclick5(val){
      
      this.setState({catexperrating: val });
    }

photoonclick(val){
     
      this.setState({photocomrating: val });
    }
	photoonclick1(val){
     
      this.setState({photoexectimerating: val });
    }
	photoonclick2(val){
      
      this.setState({photoprofesrating: val });
    }
	photoonclick3(val){
     
      this.setState({photocrativityrating: val });
    }
	photoonclick4(val){
     
      this.setState({photovalmoneyrating: val });
    }
	photoonclick5(val){
      
      this.setState({photoexperrating: val });
    }
maonclick(val){
     
      this.setState({macomrating: val });
    }
	maonclick1(val){
     
      this.setState({maexectimerating: val });
    }
	maonclick2(val){
      
      this.setState({maprofesrating: val });
    }
	maonclick3(val){
     
      this.setState({macrativityrating: val });
    }
	maonclick4(val){
     
      this.setState({mavalmoneyrating: val });
    }
	maonclick5(val){
      
      this.setState({maexperrating: val });
    }	
	
handleNext1 = (e) => {


localStorage.setItem("vetype", 1);






				 let venuerating=this.state.venuerating;
				
				 localStorage.setItem("venuerating", venuerating);
				  
				 let servicerating=this.state.servicerating;
				 localStorage.setItem("servicerating", servicerating);
				 
				 let cleanlinessrating=this.state.cleanlinessrating;
				 localStorage.setItem("cleanlinessrating", cleanlinessrating);
				 let supervisorrating=this.state.supervisorrating;
				 localStorage.setItem("supervisorrating", supervisorrating);
				 let staffrating=this.state.staffrating;
				 localStorage.setItem("staffrating", staffrating);
				 				 let roomrating=this.state.roomrating;
				 localStorage.setItem("roomrating", roomrating);
				 				 let overallrating=this.state.overallrating;
				 localStorage.setItem("overallrating", overallrating);
				 let otherfeedback=this.state.otherfeedback;
				 localStorage.setItem("otherfeedback", otherfeedback);
						
				if(venuerating == "0")
		{			
			this.setState({errormessage:"Please Rate for Venue",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
					
				if(servicerating == "0")
		{			
			this.setState({errormessage:"Please Rate for Service",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
			if(cleanlinessrating == "0")
		{			
			this.setState({errormessage:"Please Rate for Cleanliness ",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
			if(supervisorrating == "0")
		{			
			this.setState({errormessage:"Please Rate for Supervisor ",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
			if(staffrating == "0")
		{			
			this.setState({errormessage:"Please Rate for Staff ",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
			if(overallrating == "0")
		{			
			this.setState({errormessage:"Please Rate for Overall ",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
			
			 			
				 this.setState({formtypes:"3",vetype:"2",activeStep: this.state.activeStep+1});
				this.setState({erropen: false}); 
this.onloadvendorbind();	
this.onloadpackagebind();

};	
handleNext2 = (e) => {
	
	localStorage.setItem("vetype", 2);
					let vendor=this.state.vendor;
					localStorage.setItem("vendor", vendor);
				 let deccomrating=this.state.deccomrating;
				 localStorage.setItem("deccomrating", deccomrating);
				 
				 let decexectimerating=this.state.decexectimerating;
				 localStorage.setItem("decexectimerating", decexectimerating);
				 let decoutputrating=this.state.decoutputrating;
				 localStorage.setItem("decoutputrating", decoutputrating);
				 let decqualityrating=this.state.decqualityrating;
				 localStorage.setItem("decqualityrating", decqualityrating);
				 let decpackage=this.state.decpackage;
				 localStorage.setItem("decpackage", decpackage);
				 let decvalmoneyrating=this.state.decvalmoneyrating;
				 localStorage.setItem("decvalmoneyrating", decvalmoneyrating);
				  let decexperrating=this.state.decexperrating;
				 localStorage.setItem("decexperrating", decexperrating);
				 let decotherfeedback=this.state.decotherfeedback;
				 localStorage.setItem("decotherfeedback", decotherfeedback);
				
				
					if(vendor === "")
		{			
			this.setState({errormessage:"Please Select Vendor",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
					if(decpackage === "")
		{			
			this.setState({errormessage:"Please Select Package",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
					
				if(deccomrating == "0")
		{			
			this.setState({errormessage:"Please Rate for Communication ",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
			if(decexectimerating == "0")
		{			
			this.setState({errormessage:"Please Rate for Execution Time ",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
			if(decoutputrating == "0")
		{			
			this.setState({errormessage:"Please Rate for Output ",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
			if(decqualityrating == "0")
		{			
			this.setState({errormessage:"Please Rate for Quality ",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
			if(decvalmoneyrating == "0")
		{			
			this.setState({errormessage:"Please Rate for Value for Money ",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
			if(decexperrating == "0")
		{			
			this.setState({errormessage:"Please Rate for Overall Experience ",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
							 
				 this.setState({formtypes:"4",vetype:"3",dectype:"1",activeStep: this.state.activeStep+1});
				 this.setState({erropen: false});	
	this.onloadvendorbind();
	this.onloadpackagebind();

};	
handleNext3 = (e) => {
	
	localStorage.setItem("vetype", 3);
					let catvendor=this.state.catvendor;
					localStorage.setItem("catvendor", catvendor);
					
				 let catcomrating=this.state.catcomrating;
				 localStorage.setItem("catcomrating", catcomrating);
				 let catexectimerating=this.state.catexectimerating;
				 localStorage.setItem("catexectimerating", catexectimerating);
				 let cattasterating=this.state.cattasterating;
				 localStorage.setItem("cattasterating", cattasterating);
				 let catqualityrating=this.state.catqualityrating;
				 localStorage.setItem("catqualityrating", catqualityrating);
				 let catpackage=this.state.catpackage;
				 localStorage.setItem("catpackage", catpackage);
				 let catvalmoneyrating=this.state.catvalmoneyrating;
				 localStorage.setItem("catvalmoneyrating", catvalmoneyrating);
				  let catexperrating=this.state.catexperrating;
				 localStorage.setItem("catexperrating", catexperrating);
				 let catotherfeedback=this.state.catotherfeedback;
				 localStorage.setItem("catotherfeedback", catotherfeedback);			
				 		if(catvendor === "")
		{			
			this.setState({errormessage:"Please Select Vendor",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
					if(catpackage === "")
		{			
			this.setState({errormessage:"Please Select Package",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
					
				if(catcomrating == "0")
		{			
			this.setState({errormessage:"Please Rate for Communication ",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
			if(catexectimerating == "0")
		{			
			this.setState({errormessage:"Please Rate for Execution Time ",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
			if(cattasterating == "0")
		{			
			this.setState({errormessage:"Please Rate for Taste ",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
			if(catqualityrating == "0")
		{			
			this.setState({errormessage:"Please Rate for Quality ",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
			if(catvalmoneyrating == "0")
		{			
			this.setState({errormessage:"Please Rate for Value for Money ",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
			if(catexperrating == "0")
		{			
			this.setState({errormessage:"Please Rate for Overall Experience ",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}		 
				 this.setState({formtypes:"5",vetype:"4",cattype:"2",activeStep: this.state.activeStep+1});
				this.setState({erropen: false}); 	
this.onloadvendorbind();	
this.onloadpackagebind();
};

handleNext4 = (e) => {
	
	localStorage.setItem("vetype", 4);
					let photovendor=this.state.photovendor;
					 localStorage.setItem("photovendor", photovendor);
				 let photocomrating=this.state.photocomrating;
				 localStorage.setItem("photocomrating", photocomrating);
				 let photoexectimerating=this.state.photoexectimerating;
				 localStorage.setItem("photoexectimerating", photoexectimerating);
				 let photoprofesrating=this.state.photoprofesrating;
				 localStorage.setItem("photoprofesrating", photoprofesrating);
				 let photocrativityrating =this.state.photocrativityrating;
				 localStorage.setItem("photocrativityrating", photocrativityrating);
				 let photopackage=this.state.photopackage;
				
				 localStorage.setItem("photopackage", photopackage);
				 let photovalmoneyrating=this.state.photovalmoneyrating;
				 localStorage.setItem("photovalmoneyrating", photovalmoneyrating);
				  let photoexperrating=this.state.photoexperrating;
				 localStorage.setItem("photoexperrating", photoexperrating);
				 let photootherfeedback=this.state.photootherfeedback;
				 localStorage.setItem("photootherfeedback", photootherfeedback);			
				 	
if(photovendor === "")
		{			
			this.setState({errormessage:"Please Select Vendor",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
					if(photopackage === "")
		{			
			this.setState({errormessage:"Please Select Package",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
					
				if(photocomrating == "0")
		{			
			this.setState({errormessage:"Please Rate for Communication ",erropen:true});
			window.scrollTo({        top: 0     })
			return false;
			}
			if(photoexectimerating == "0")
		{			
			this.setState({errormessage:"Please Rate for Execution Time ",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
			if(photoprofesrating == "0")
		{			
			this.setState({errormessage:"Please Rate for Professionalism",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
			if(photocrativityrating == "0")
		{			
			this.setState({errormessage:"Please Rate for Creativity ",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
			if(photovalmoneyrating == "0")
		{			
			this.setState({errormessage:"Please Rate for Value for Money ",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
			if(photoexperrating == "0")
		{			
			this.setState({errormessage:"Please Rate for Overall Experience ",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}		 
			
					
				 this.setState({formtypes:"6",phototype:"3",activeStep: this.state.activeStep+1});
this.onloadvendorbind();	
this.onloadpackagebind();	

};
handleNext5 = (e) => {
					let mavendor=this.state.mavendor;
					localStorage.setItem("mavendor", mavendor);
				 let macomrating=this.state.macomrating;
				 localStorage.setItem("macomrating", macomrating);
				 let maexectimerating=this.state.maexectimerating;
				 localStorage.setItem("maexectimerating", maexectimerating);
				 let maprofesrating=this.state.maprofesrating;
				 localStorage.setItem("maprofesrating", maprofesrating);
				 let macrativityrating =this.state.macrativityrating;
				 localStorage.setItem("macrativityrating", macrativityrating);
				 let mapackage=this.state.mapackage;
				
				 localStorage.setItem("mapackage", mapackage);
				 let mavalmoneyrating=this.state.mavalmoneyrating;
				 localStorage.setItem("mavalmoneyrating", mavalmoneyrating);
				  let maexperrating=this.state.maexperrating;
				 localStorage.setItem("maexperrating", maexperrating);
				 let maotherfeedback=this.state.maotherfeedback;
				 localStorage.setItem("maotherfeedback", maotherfeedback);			
					let matype=4;
				if(mavendor === "")
		{			
			this.setState({errormessage:"Please Select Vendor",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
					if(mapackage === "")
		{			
			this.setState({errormessage:"Please Select Package",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
					
				if(macomrating == "0")
		{			
			this.setState({errormessage:"Please Rate for Communication ",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
			if(maexectimerating == "0")
		{			
			this.setState({errormessage:"Please Rate for Execution Time ",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
			if(maprofesrating == "0")
		{			
			this.setState({errormessage:"Please Rate for Professionalism ",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
			if(macrativityrating == "0")
		{			
			this.setState({errormessage:"Please Rate for Creativity ",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
			if(mavalmoneyrating == "0")
		{			
			this.setState({errormessage:"Please Rate for Value for Money ",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}
			if(maexperrating == "0")
		{			
			this.setState({errormessage:"Please Rate for Overall Experience",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			}		 
			let dectype=this.state.dectype;
			let cattype=this.state.cattype;
			let phototype=this.state.phototype;
			 this.setState({secondarytext:'Processing...',open:false});
		var formData = new FormData();			 
		formData.append('action','insert')		
		formData.append('cu_st_id',localStorage.getItem("staff"))		
		formData.append('cu_name',localStorage.getItem("name"))	
		formData.append('cu_venue',localStorage.getItem("venue"))	
		formData.append('cu_venuefeedback',localStorage.getItem("venuerating"))	
		formData.append('cu_cleanliness',localStorage.getItem("cleanlinessrating"))	
		formData.append('cu_overallexperience',localStorage.getItem("overallrating"))	
		formData.append('cu_otherfeedback',localStorage.getItem("otherfeedback"))	
		formData.append('cu_service',localStorage.getItem("servicerating"))	
		formData.append('cu_superviser',localStorage.getItem("supervisorrating"))	
		formData.append('cu_rooms',localStorage.getItem("roomrating"))	
		formData.append('cu_functiondate',localStorage.getItem("fdate1"))
		formData.append('cu_feedbackgivenby',localStorage.getItem("feedbackby"))
		formData.append('cu_feedbacktakenby',localStorage.getItem("staff"))
		
		formData.append('vendor_id',localStorage.getItem("vendor"))	
formData.append('decpackage_id',localStorage.getItem("decpackage"))	
formData.append('decotherfeedback',localStorage.getItem("decotherfeedback"))	
formData.append('dectype',dectype)	
formData.append('decoverallexperience',localStorage.getItem("decexperrating"))	
formData.append('decvalueformoney',localStorage.getItem("decvalmoneyrating"))	
formData.append('deccommunication',localStorage.getItem("deccomrating"))	
formData.append('decexecutiontime',localStorage.getItem("decexectimerating"))	
formData.append('decoutput',localStorage.getItem("decoutputrating"))	
formData.append('decquality',localStorage.getItem("decqualityrating"))	

formData.append('catvendor_id',localStorage.getItem("catvendor"))	
formData.append('catpackage_id',localStorage.getItem("catpackage"))	
formData.append('catotherfeedback',localStorage.getItem("catotherfeedback"))	
formData.append('cattype',cattype)	
formData.append('catoverallexperience',localStorage.getItem("catexperrating"))	
formData.append('catvalueformoney',localStorage.getItem("catvalmoneyrating"))	
formData.append('catcommunication',localStorage.getItem("catcomrating"))	
formData.append('catexecutiontime',localStorage.getItem("catexectimerating"))	
formData.append('catoutput',localStorage.getItem("cattasterating"))	
formData.append('catquality',localStorage.getItem("catqualityrating"))	

formData.append('photovendor_id',localStorage.getItem("photovendor"))	
formData.append('photopackage_id',localStorage.getItem("photopackage"))	
formData.append('photootherfeedback',localStorage.getItem("photootherfeedback"))	
formData.append('phototype',phototype)	
formData.append('photooverallexperience',localStorage.getItem("photoexperrating"))	
formData.append('photovalueformoney',localStorage.getItem("photovalmoneyrating"))	
formData.append('photocommunication',localStorage.getItem("photocomrating"))	
formData.append('photoexecutiontime',localStorage.getItem("photoexectimerating"))	
formData.append('photooutput',localStorage.getItem("photoprofesrating"))	
formData.append('photoquality',localStorage.getItem("photocrativityrating"))	

formData.append('mavendor_id',localStorage.getItem("mavendor"))	
formData.append('mapackage_id',localStorage.getItem("mapackage"))	
formData.append('maotherfeedback',localStorage.getItem("maotherfeedback"))	
formData.append('matype',matype)	
formData.append('maoverallexperience',localStorage.getItem("maexperrating"))	
formData.append('mavalueformoney',localStorage.getItem("mavalmoneyrating"))	
formData.append('macommunication',localStorage.getItem("macomrating"))	
formData.append('maexecutiontime',localStorage.getItem("maexectimerating"))	
formData.append('maoutput',localStorage.getItem("maprofesrating"))	
formData.append('maquality',localStorage.getItem("macrativityrating"))
			
		
		
		formData.append('userid',localStorage.getItem("YSuserid"))				  
		
		axios.post(Serverurl+"ajaxfeedback.php", formData, {
				}).then(res => {

					var str=res.data;
this.setState({secondarytext:'Finish',errormessage:'',open:false});					
localStorage.removeItem("name");	
		localStorage.removeItem("venue");	
		localStorage.removeItem("venuerating");	
	localStorage.removeItem("cleanlinessrating");	
		localStorage.removeItem("overallrating");	
		localStorage.removeItem("otherfeedback");	
		localStorage.removeItem("servicerating");	
		localStorage.removeItem("supervisorrating");	
		localStorage.removeItem("roomrating");	
		localStorage.removeItem("fdate1");
		localStorage.removeItem("feedbackby");
	localStorage.removeItem("staff");
		localStorage.removeItem("vendor");	
localStorage.removeItem("decpackage");	
localStorage.removeItem("decotherfeedback");	
localStorage.removeItem("decexperrating");	
localStorage.removeItem("decvalmoneyrating");	
localStorage.removeItem("deccomrating");	
localStorage.removeItem("decexectimerating");	
localStorage.removeItem("decoutputrating");	
localStorage.removeItem("decqualityrating");
localStorage.removeItem("catvendor");	
localStorage.removeItem("catpackage");	
localStorage.removeItem("catotherfeedback");	
localStorage.removeItem("catexperrating");	
localStorage.removeItem("catvalmoneyrating");	
localStorage.removeItem("catcomrating");	
localStorage.removeItem("catexectimerating");	
localStorage.removeItem("catoutputrating");	
localStorage.removeItem("catqualityrating");
localStorage.removeItem("photovendor");	
localStorage.removeItem("photopackage");	
localStorage.removeItem("photootherfeedback");	
localStorage.removeItem("photoexperrating");	
localStorage.removeItem("photovalmoneyrating");	
localStorage.removeItem("photocomrating");	
localStorage.removeItem("photoexectimerating");	
localStorage.removeItem("photooutputrating");	
localStorage.removeItem("photoqualityrating");	
localStorage.removeItem("mavendor");	
localStorage.removeItem("mapackage");	
localStorage.removeItem("maotherfeedback");	
localStorage.removeItem("maexperrating");	
localStorage.removeItem("mavalmoneyrating");	
localStorage.removeItem("macomrating");	
localStorage.removeItem("maexectimerating");	
localStorage.removeItem("maoutputrating");	
localStorage.removeItem("maqualityrating");					
		this.setState({redirect:true,pagename:'/Feedbackthankyou'})
		
			
				});
		  
				this.setState({ open:false });
		
		};
 handleErrorclose=(event)=>{
	  
		this.setState({erropen: false});  
	 } 
	
	render(){
		if (this.state.redirect === true) {
		return <Navigate to={this.state.pagename} />
		}
		
	return (
	
			
		
		<React.Fragment>
		
	<React.Fragment>
	<div className="headers">
		<h1> Feedback</h1> 
		</div>
	</React.Fragment>
		
		<form className={useStyles.form}   >	
<div className="stepperheader">		
		
		 <Stepper activeStep={this.state.activeStep}>		 	
        <Step>
          <StepLabel>Customer</StepLabel>
        </Step>
        <Step>
          <StepLabel>Venue</StepLabel>
        </Step>
        <Step>
          <StepLabel>Decor</StepLabel>
        </Step>
		<Step>
          <StepLabel>Catering </StepLabel>
        </Step>
		<Step>
          <StepLabel>Photography </StepLabel>
        </Step>
		<Step>
          <StepLabel>Makeup </StepLabel>
        </Step>
      </Stepper>
	  </div>
{this.state.formtypes === "1" &&(<React.Fragment>
<div className="header">
		<h2> Customer</h2> 
		</div>
		<div className="feedbackmain">
		<p> 
				<Collapse in={this.state.erropen}>
				<Alert
				  action={
					<IconButton
					  aria-label="close"
					  color="inherit"
					  size="small"
					  onClick={() => {
					   this.handleErrorclose();
					  }}
					>
					  <CloseIcon fontSize="inherit" />
					</IconButton>
				  } severity="error"
				>
				{this.state.errormessage}
				</Alert>
			  </Collapse>
			</p>
			
			<div className="feedbackleft">
		<p><span className="validationtxt">*</span>Customer Name 
		<TextField
            variant="outlined"
            margin="normal"
            fullWidth value={this.state.cname}
           inputProps={{tabIndex: "1",maxLength:150}}
		   InputLabelProps={{ shrink: true }} 
			placeholder="Customer Name"
            name="cname" onChange={this.myChangeHandler}
		
          /></p>
		 
	 <p><span className="validationtxt">*</span>Function Date
	<MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">	 
      <DatePicker fullWidth
        value={this.state.fdate || Date.now()}
        onChange={this.handfrChanges}
		InputLabelProps={{ shrink: true }} 
		variant="inline"
		inputVariant="outlined"
		 margin="normal"    
maxDate={moment().toDate()} 
            format="dd/MM/yyyy"			
        autoOk name="fdate"
        ampm={false}
      />
	   </Grid>
    </MuiPickersUtilsProvider></p>
	<p><span className="validationtxt">*</span>Venue</p>
<p><FormControl  className="stadrp">			   
		<Select
		variant="outlined"
		native
		value={this.state.venue}
		onChange={this.myChangeHandler}
		InputLabelProps={{ shrink: true }} 
		name='venue'
		id='venue' 
		InputLabelProps={{
		shrink: true,
		}}
		>
		<option value={0}>Select</option>
		<option value="1">Yashaswi</option>
		<option value="2">The New Castle</option>	
		<option value="3">Spectra</option>		
		</Select>
		</FormControl></p>
		</div>
		<div className="feedbackright">
		<p><span className="validationtxt">*</span>Feedback Given By
		<TextField
            variant="outlined"
            margin="normal"
            fullWidth value={this.state.cuname}
           inputProps={{tabIndex: "4",maxLength:150}} InputLabelProps={{ shrink: true }} 
			placeholder="Feedback given By"
            name="cuname" onChange={this.myChangeHandler}		
          /></p>
		  
		<p><span className="validationtxt">*</span>Feedback Taken By </p>
		<p>
		<FormControl  className="stadrp">
         
        <Select
         native  fullWidth		 
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.rolelabel, value: this.state.staff }}
          onChange={this.myChangeHandler}
          id="staff"
		   value={this.state.staff}
		  name="staff"
		
		
        >
		{this.state.staffarray.map(staff => (
            <option  
            
              value={staff.value}
            >
              {staff.display}
            </option  >
          ))}
		
        </Select></FormControl>
		
				</p>
		</div>
		<div className="clear"></div>

		<DialogActions>
		 
		<div className="actionbtns">			 
		<div className="savebtns">
		<Button onClick={this.handleNext} className="savebttn">
		Next
		</Button>
		</div>
		<div className="clear"></div>
		</div>

		</DialogActions>
		</div>
		</React.Fragment>)}	
		{this.state.formtypes === "2" &&(<React.Fragment>
		<div className="header">
		<h2>Venue</h2> </div>
		<div className="feedbackmain">
		<p> 
				<Collapse in={this.state.erropen}>
				<Alert
				  action={
					<IconButton
					  aria-label="close"
					  color="inherit"
					  size="small"
					  onClick={() => {
					   this.handleErrorclose();
					  }}
					>
					  <CloseIcon fontSize="inherit" />
					</IconButton>
				  } severity="error"
				>
				{this.state.errormessage}
				</Alert>
			  </Collapse>
			</p>
		<div className="feedbackleft">
		<div className="feedbackcollft"><p>Venue </p></div>
<div className="feedbackcollrt"><p>
		<Box
      sx={{
        '& > legend': { mt: 2 },
      }} class="inputrating"
    >
      
      <Rating
        name="venuerating"
        value={this.state.venuerating}
        onChange={(event, newValue) => {
          this.onclick(newValue)
        }} 
      />
     
    </Box></p>
		 </div>
		 <div className="clear"></div>
	<div className="feedbackcollft"><p>Sevice  </p></div>
<div className="feedbackcollrt"><p>
	<Box
      sx={{
        '& > legend': { mt: 2 },
      }} class="inputrating"
    >
      
      <Rating
        name="servicerating"
        value={this.state.servicerating}
        onChange={(event, newValue) => {
          this.onclick1(newValue)
        }} 
      />
     
    </Box></p>
		 </div>
		 <div className="clear"></div>
	
	<div className="feedbackcollft"><p>Cleanliness </p></div>
<div className="feedbackcollrt"><p> <Box
      sx={{
        '& > legend': { mt: 2 },
      }} class="inputrating"
    >
      
      <Rating
        name="cleanlinessrating"
        value={this.state.cleanlinessrating}
        onChange={(event, newValue) => {
          this.onclick2(newValue)
        }} 
      />
     
    </Box></p>
		 </div>
		 <div className="clear"></div>	

<div className="feedbackcollft"><p>Supervisor  </p></div>
<div className="feedbackcollrt"><p><Box
      sx={{
        '& > legend': { mt: 2 },
      }} class="inputrating"
    >
      
      <Rating
        name="supervisorrating"
        value={this.state.supervisorrating}
        onChange={(event, newValue) => {
          this.onclick3(newValue)
        }} 
      />
     
    </Box></p>
		 </div>
		 <div className="clear"></div>
	
	<div className="feedbackcollft"><p>Staff  </p></div>
<div className="feedbackcollrt"><p><Box
      sx={{
        '& > legend': { mt: 2 },
      }} class="inputrating"
    >
      
      <Rating
        name="staffrating"
        value={this.state.staffrating}
        onChange={(event, newValue) => {
          this.onclick4(newValue)
        }} 
      />
     
    </Box></p>
		 </div>
		 <div className="clear"></div>
	
<div className="feedbackcollft"><p>Room  </p></div>
<div className="feedbackcollrt"><p><Box
      sx={{
        '& > legend': { mt: 2 },
      }} class="inputrating"
    >
      
      <Rating
        name="roomrating"
        value={this.state.roomrating}
        onChange={(event, newValue) => {
          this.onclick5(newValue)
        }} 
      />
     
    </Box></p>
		 </div>
		 <div className="clear"></div>
	<div className="feedbackcollft"><p>Overall Experience   </p></div>
<div className="feedbackcollrt"><p><Box
      sx={{
        '& > legend': { mt: 2 },
      }} class="inputrating"
    >
      
      <Rating
        name="overallrating"
        value={this.state.overallrating}
        onChange={(event, newValue) => {
          this.onclick6(newValue)
        }} 
      />
     
    </Box></p>
		 </div>
		 <div className="clear"></div>
	<div className="feedbackcollft"><p>Other Feedback</p></div>
	<div className="feedbackcollrt">
	<p><TextField name="otherfeedback" 
	   onChange={this.myChangeHandler} 
	   fullWidth
	   multiline
          rows={2}
	   value={this.state.otherfeedback}
			 id="otherfeedback"
			  ref={this.usernameInput}
			  inputProps={{tabIndex: "4",maxLength:150}} 
	   InputLabelProps={{ shrink: true }}
                          /></p>
						  </div>
						  <div className="clear"></div>
</div>		
	<div className="clear"></div>
		<DialogActions>
		 
		<div className="actionbtns">

		
		<div className="delbtns">
		<Button onClick={this.handleback1} className="delbttn" >
		Back 
		</Button>
		</div>
		<div className="savebtns">
		<Button onClick={this.handleNext1} className="savebttn" >
		Next
		</Button>
		</div>
		<div className="clear"></div>
		</div>
		</DialogActions>
		</div>
		</React.Fragment>)}	  
		
		{this.state.formtypes==="3" &&(<React.Fragment>
		<div className="header">
		<h2>Decor </h2> </div>
		<div className="feedbackmain">
		<p> 
				<Collapse in={this.state.erropen}>
				<Alert
				  action={
					<IconButton
					  aria-label="close"
					  color="inherit"
					  size="small"
					  onClick={() => {
					   this.handleErrorclose();
					  }}
					>
					  <CloseIcon fontSize="inherit" />
					</IconButton>
				  } severity="error"
				>
				{this.state.errormessage}
				</Alert>
			  </Collapse>
			</p>
		<div className="feedbackleft">
		<div className="feedbackcollft"><p className="vendrtag"><span className="validationtxt">*</span>Vendor</p></div>
<div className="feedbackcollrt"><p>
		<FormControl  className="stadrp">
         
        <Select
         native  fullWidth		 
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.rolelabel, value: this.state.vendor }}
          onChange={this.decmyChangeHandler}
          id="vendor"
		   value={this.state.vendor}
		  name="vendor"
		
		
        >
		{this.state.vendorarray.map(vendor => (
            <option  
            
              value={vendor.value}
            >
              {vendor.display}
            </option  >
          ))}
		
        </Select></FormControl>
		</p></div>
		<div className="clear"></div>
		<div className="feedbackcollft"><p className="packtag"><span className="validationtxt">*</span>Package</p></div>
<div className="feedbackcollrt"><p>
<TextField
            variant="outlined"
            margin="normal"
            fullWidth value={this.state.decpackage}
           inputProps={{tabIndex: "4",maxLength:150}} InputLabelProps={{ shrink: true }} 
			placeholder="Package"
            name="decpackage" onChange={this.myChangeHandler}		
          />

		
		</p></div>
		<div className="clear"></div>
		<div className="feedbackcollft"><p>Communication </p></div>
<div className="feedbackcollrt"><p> <Box
      sx={{
        '& > legend': { mt: 2 },
      }} class="inputrating"
    >
      
      <Rating
        name="deccomrating"
        value={this.state.deccomrating}
        onChange={(event, newValue) => {
          this.deconclick(newValue)
        }} 
      />
     
    </Box></p>
		 </div>
		 <div className="clear"></div>
		 
	<div className="feedbackcollft"><p>Excecution Time  </p></div>
<div className="feedbackcollrt"><p><Box
      sx={{
        '& > legend': { mt: 2 },
      }} class="inputrating"
    >
      
      <Rating
        name="decexectimerating"
        value={this.state.decexectimerating}
        onChange={(event, newValue) => {
          this.deconclick1(newValue)
        }} 
      />
     
    </Box></p>
		 </div>
		 <div className="clear"></div>
	<div className="feedbackcollft"><p>Output  </p></div>
<div className="feedbackcollrt"><p><Box
      sx={{
        '& > legend': { mt: 2 },
      }} class="inputrating"
    >
      
      <Rating
        name="decoutputrating"
        value={this.state.decoutputrating}
        onChange={(event, newValue) => {
          this.deconclick2(newValue)
        }} 
      />
     
    </Box></p></div>
		 <div className="clear"></div>
		 
<div className="feedbackcollft"><p>Quality </p></div>
<div className="feedbackcollrt"><p> <Box
      sx={{
        '& > legend': { mt: 2 },
      }} class="inputrating"
    >
      
      <Rating
        name="decqualityrating"
        value={this.state.decqualityrating}
        onChange={(event, newValue) => {
          this.deconclick3(newValue)
        }} 
      />
     
    </Box></p>
		 </div>
		 <div className="clear"></div>
	
	
	</div>
		<div className="feedbackright">
		
		<div className="feedbackcollft"><p>Value For Money </p></div>
<div className="feedbackcollrt"><p><Box
      sx={{
        '& > legend': { mt: 2 },
      }} class="inputrating"
    >
      
      <Rating
        name="decvalmoneyrating"
        value={this.state.decvalmoneyrating}
        onChange={(event, newValue) => {
          this.deconclick4(newValue)
        }} 
      />
     
    </Box></p>
		 </div>
		 <div className="clear"></div>
<div className="feedbackcollft"><p>Overall Experience  </p></div>
<div className="feedbackcollrt"><p><Box
      sx={{
        '& > legend': { mt: 2 },
      }} class="inputrating"
    >
      
      <Rating
        name="decexperrating"
        value={this.state.decexperrating}
        onChange={(event, newValue) => {
          this.deconclick5(newValue)
        }} 
      />
     
    </Box></p>
		 </div>
		 <div className="clear"></div>
	
	<div className="feedbackcollft"><p>Other Feedback</p></div>
	<div className="feedbackcollrt"><p><TextField name="decotherfeedback" 
	   onChange={this.myChangeHandler} 
	   fullWidth
	   multiline
          rows={2}
	   value={this.state.decotherfeedback}
			 
			  ref={this.usernameInput}
			  inputProps={{tabIndex: "4",maxLength:150}} 
	   InputLabelProps={{ shrink: true }}
                          /></p></div>
						  <div className="clear"></div>
</div>		
	<div className="clear"></div>
		<DialogActions>
		 
		<div className="actionbtns">

		
		<div className="delbtns">
		<Button onClick={this.handleback2} className="delbttn">
          Back 
		</Button>
		</div>
		<div className="savebtns">
		<Button onClick={this.handleNext2} className="savebttn">
		Next
		</Button>
		</div>
		<div className="clear"></div>
		</div>
		</DialogActions>
		</div>
		</React.Fragment>)}	  
		
		
		{this.state.formtypes==="4" &&(<React.Fragment>
		<div className="header">
		<h2>Catering  </h2> </div>
		<div className="feedbackmain">
		<p> 
				<Collapse in={this.state.erropen}>
				<Alert
				  action={
					<IconButton
					  aria-label="close"
					  color="inherit"
					  size="small"
					  onClick={() => {
					   this.handleErrorclose();
					  }}
					>
					  <CloseIcon fontSize="inherit" />
					</IconButton>
				  } severity="error"
				>
				{this.state.errormessage}
				</Alert>
			  </Collapse>
			</p>
		<div className="feedbackleft">
		<div className="feedbackcollft"><p className="vendrtag"><span className="validationtxt">*</span>Vendor</p></div>
<div className="feedbackcollrt"><p>
		<FormControl  className="stadrp">
         
        <Select
         native  fullWidth		 
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.rolelabel, value: this.state.vendor }}
          onChange={this.catmyChangeHandler}
          id="catvendor"
		   value={this.state.catvendor}
		  name="catvendor"
		
		
        >
		{this.state.vendorarray.map(vendor => (
            <option  
            
              value={vendor.value}
            >
              {vendor.display}
            </option  >
          ))}
		
        </Select></FormControl>
		</p></div>
		<div className="clear"></div>
		<div className="feedbackcollft"><p className="packtag"><span className="validationtxt">*</span>Package</p></div>
<div className="feedbackcollrt"><p>

<TextField
            variant="outlined"
            margin="normal"
            fullWidth value={this.state.catpackage}
           inputProps={{tabIndex: "4",maxLength:150}} InputLabelProps={{ shrink: true }} 
			placeholder="Package"
            name="catpackage" onChange={this.myChangeHandler}		
          />
		
		</p></div>
		<div className="clear"></div>
		<div className="feedbackcollft"><p>Communication </p></div>
<div className="feedbackcollrt"><p> <Box
      sx={{
        '& > legend': { mt: 2 },
      }} class="inputrating"
    >
      
      <Rating
        name="catcomrating"
        value={this.state.catcomrating}
        onChange={(event, newValue) => {
          this.catonclick(newValue)
        }} 
      />
     
    </Box></p>
		 </div>
		 <div className="clear"></div>
		 
	<div className="feedbackcollft"><p>Excecution Time  </p></div>
<div className="feedbackcollrt"><p><Box
      sx={{
        '& > legend': { mt: 2 },
      }} class="inputrating"
    >
      
      <Rating
        name="catexectimerating"
        value={this.state.catexectimerating}
        onChange={(event, newValue) => {
          this.catonclick1(newValue)
        }} 
      />
     
    </Box></p>
		 </div>
		 <div className="clear"></div>
	<div className="feedbackcollft"><p>Taste  </p></div>
<div className="feedbackcollrt"><p><Box
      sx={{
        '& > legend': { mt: 2 },
      }} class="inputrating"
    >
      
      <Rating
        name="cattasterating"
        value={this.state.cattasterating}
        onChange={(event, newValue) => {
          this.catonclick2(newValue)
        }} 
      />
     
    </Box></p></div>
		 <div className="clear"></div>
		 
<div className="feedbackcollft"><p>Quality </p></div>
<div className="feedbackcollrt"><p> <Box
      sx={{
        '& > legend': { mt: 2 },
      }} class="inputrating"
    >
      
      <Rating
        name="catqualityrating"
        value={this.state.catqualityrating}
        onChange={(event, newValue) => {
          this.catonclick3(newValue)
        }} 
      />
     
    </Box></p>
		 </div>
		 <div className="clear"></div>
	
	
	</div>
		<div className="feedbackright">
		
		<div className="feedbackcollft"><p>Value For Money  </p></div>
<div className="feedbackcollrt"><p><Box
      sx={{
        '& > legend': { mt: 2 },
      }} class="inputrating"
    >
      
      <Rating
        name="catvalmoneyrating"
        value={this.state.catvalmoneyrating}
        onChange={(event, newValue) => {
          this.catonclick4(newValue)
        }} 
      />
     
    </Box></p>
		 </div>
		 <div className="clear"></div>
<div className="feedbackcollft"><p>Overall Experience  </p></div>
<div className="feedbackcollrt"><p><Box
      sx={{
        '& > legend': { mt: 2 },
      }} class="inputrating"
    >
      
      <Rating
        name="catexperrating"
        value={this.state.catexperrating}
        onChange={(event, newValue) => {
          this.catonclick5(newValue)
        }} 
      />
     
    </Box></p>
		 </div>
		 <div className="clear"></div>
	
	<div className="feedbackcollft"><p>Other Feedback</p>
	</div>
<div className="feedbackcollrt"><p><TextField name="catotherfeedback" 
	   onChange={this.myChangeHandler} 
	   fullWidth
	   multiline
          rows={2}
	   value={this.state.catotherfeedback}
			 
			  ref={this.usernameInput}
			  inputProps={{tabIndex: "4",maxLength:150}} 
	   InputLabelProps={{ shrink: true }}
                          /></p></div>
						  <div className="clear"></div>
</div>		
	<div className="clear"></div>
		<DialogActions>
		 
		<div className="actionbtns">

		
		<div className="delbtns">
		<Button onClick={this.handleback3} className="delbttn">
		Back
		</Button>
		</div>
		<div className="savebtns">
		<Button onClick={this.handleNext3} className="savebttn">
		Next
		</Button>
		</div>
		<div className="clear"></div>
		</div>
		</DialogActions>
		</div>
		</React.Fragment>)}	 
		
		
			{this.state.formtypes==="5" &&(<React.Fragment>
		<div className="header">
		<h2>Photography</h2> </div>
		<div className="feedbackmain">
		<p> 
				<Collapse in={this.state.erropen}>
				<Alert
				  action={
					<IconButton
					  aria-label="close"
					  color="inherit"
					  size="small"
					  onClick={() => {
					   this.handleErrorclose();
					  }}
					>
					  <CloseIcon fontSize="inherit" />
					</IconButton>
				  } severity="error"
				>
				{this.state.errormessage}
				</Alert>
			  </Collapse>
			</p>
		<div className="feedbackleft">
		<div className="feedbackcollft"><p className="vendrtag"><span className="validationtxt">*</span>Vendor</p></div>
<div className="feedbackcollrt"><p>
		<FormControl  className="stadrp">
         
        <Select
         native  fullWidth		 
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.rolelabel, value: this.state.vendor }}
          onChange={this.photomyChangeHandler}
          id="photovendor"
		   value={this.state.photovendor}
		  name="photovendor"
		
		
        >
		{this.state.vendorarray.map(vendor => (
            <option  
            
              value={vendor.value}
            >
              {vendor.display}
            </option  >
          ))}
		
        </Select></FormControl>
		</p></div>
		<div className="clear"></div>
		<div className="feedbackcollft"><p className="packtag"><span className="validationtxt">*</span>Package</p></div>
<div className="feedbackcollrt"><p>

<TextField
            variant="outlined"
            margin="normal"
            fullWidth value={this.state.photopackage}
           inputProps={{tabIndex: "4",maxLength:150}} InputLabelProps={{ shrink: true }} 
			placeholder="Package"
			id="photopackage"
            name="photopackage" onChange={this.myChangeHandler}		
          />
		
		</p></div>
		<div className="clear"></div>
		<div className="feedbackcollft"><p>Communication </p></div>
<div className="feedbackcollrt"><p> <Box
      sx={{
        '& > legend': { mt: 2 },
      }} class="inputrating"
    >
      
      <Rating
        name="photocomrating"
        value={this.state.photocomrating}
        onChange={(event, newValue) => {
          this.photoonclick(newValue)
        }} 
      />
     
    </Box></p>
		 </div>
		 <div className="clear"></div>
		 
	<div className="feedbackcollft"><p>Excecution Time  </p></div>
<div className="feedbackcollrt"><p><Box
      sx={{
        '& > legend': { mt: 2 },
      }} class="inputrating"
    >
      
      <Rating
        name="photoexectimerating"
        value={this.state.photoexectimerating}
        onChange={(event, newValue) => {
          this.photoonclick1(newValue)
        }} 
      />
     
    </Box></p>
		 </div>
		 <div className="clear"></div>
	<div className="feedbackcollft"><p>Professionalism  </p></div>
<div className="feedbackcollrt"><p><Box
      sx={{
        '& > legend': { mt: 2 },
      }} class="inputrating"
    >
      
      <Rating
        name="photoprofesrating"
        value={this.state.photoprofesrating}
        onChange={(event, newValue) => {
          this.photoonclick2(newValue)
        }} 
      />
     
    </Box></p></div>
		 <div className="clear"></div>
		 
<div className="feedbackcollft"><p>Creativity </p></div>
<div className="feedbackcollrt"><p> <Box
      sx={{
        '& > legend': { mt: 2 },
      }} class="inputrating"
    >
      
      <Rating
        name="photocrativityrating"
        value={this.state.photocrativityrating}
        onChange={(event, newValue) => {
          this.photoonclick3(newValue)
        }} 
      />
     
    </Box></p>
		 </div>
		 <div className="clear"></div>
	
	
	</div>
		<div className="feedbackright">
		
		<div className="feedbackcollft"><p>Value For Money  </p></div>
<div className="feedbackcollrt"><p><Box
      sx={{
        '& > legend': { mt: 2 },
      }} class="inputrating"
    >
      
      <Rating
        name="photovalmoneyrating"
        value={this.state.photovalmoneyrating}
        onChange={(event, newValue) => {
          this.photoonclick4(newValue)
        }} 
      />
     
    </Box></p>
		 </div>
		 <div className="clear"></div>
<div className="feedbackcollft"><p>Overall Experience  </p></div>
<div className="feedbackcollrt"><p><Box
      sx={{
        '& > legend': { mt: 2 },
      }} class="inputrating"
    >
      
      <Rating
        name="photoexperrating"
        value={this.state.photoexperrating}
        onChange={(event, newValue) => {
          this.photoonclick5(newValue)
        }} 
      />
     
    </Box></p>
		 </div>
		 <div className="clear"></div>
	
	<div className="feedbackcollft"><p>Other Feedback</p></div>
	<div className="feedbackcollrt"><p><TextField name="photootherfeedback" 
	   onChange={this.myChangeHandler} 
	   fullWidth
	   multiline
          rows={2}
	   value={this.state.photootherfeedback}
			 
			  ref={this.usernameInput}
			  inputProps={{tabIndex: "4",maxLength:150}} 
	   InputLabelProps={{ shrink: true }}
                          /></p></div>
		 <div className="clear"></div>
</div>		
	<div className="clear"></div>
		<DialogActions>
		 
		<div className="actionbtns">

		
		<div className="delbtns">
		<Button onClick={this.handleback4} className="delbttn">
		Back
		</Button>
		</div>
		<div className="savebtns">
		<Button onClick={this.handleNext4} className="savebttn">
		Next
		</Button>
		</div>
		<div className="clear"></div>
		</div>
		</DialogActions>
		</div>
		</React.Fragment>)}	 
		
		
			{this.state.formtypes==="6" &&(<React.Fragment>
		<div className="header">
		<h2>Makeup </h2></div> 
		<div className="feedbackmain">
		<p> 
				<Collapse in={this.state.erropen}>
				<Alert
				  action={
					<IconButton
					  aria-label="close"
					  color="inherit"
					  size="small"
					  onClick={() => {
					   this.handleErrorclose();
					  }}
					>
					  <CloseIcon fontSize="inherit" />
					</IconButton>
				  } severity="error"
				>
				{this.state.errormessage}
				</Alert>
			  </Collapse>
			</p>
		<div className="feedbackleft">
		<div className="feedbackcollft"><p className="vendrtag"><span className="validationtxt">*</span>Vendor</p></div>
<div className="feedbackcollrt"><p>
		<FormControl  className="stadrp">
         
        <Select
         native  fullWidth		 
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.rolelabel, value: this.state.vendor }}
          onChange={this.mamyChangeHandler}
          id="mavendor"
		   value={this.state.mavendor}
		  name="mavendor"
		
		
        >
		{this.state.vendorarray.map(vendor => (
            <option  
            
              value={vendor.value}
            >
              {vendor.display}
            </option  >
          ))}
		
        </Select></FormControl>
		</p></div>
		<div className="clear"></div>
		<div className="feedbackcollft"><p className="packtag"><span className="validationtxt">*</span>Package</p></div>
<div className="feedbackcollrt"><p>
<TextField
            variant="outlined"
            margin="normal"
            fullWidth value={this.state.mapackage}
           inputProps={{tabIndex: "4",maxLength:150}} InputLabelProps={{ shrink: true }} 
			placeholder="Package"
			id="mapackage"
            name="mapackage" onChange={this.myChangeHandler}		
          />
		
		</p></div>
		<div className="clear"></div>
		<div className="feedbackcollft"><p>Communication </p></div>
<div className="feedbackcollrt"><p> <Box
      sx={{
        '& > legend': { mt: 2 },
      }} class="inputrating"
    >
      
      <Rating
        name="macomrating"
        value={this.state.macomrating}
        onChange={(event, newValue) => {
          this.maonclick(newValue)
        }} 
      />
     
    </Box></p>
		 </div>
		 <div className="clear"></div>
		 
	<div className="feedbackcollft"><p>Excecution Time  </p></div>
<div className="feedbackcollrt"><p><Box
      sx={{
        '& > legend': { mt: 2 },
      }} class="inputrating"
    >
      
      <Rating
        name="maexectimerating"
        value={this.state.maexectimerating}
        onChange={(event, newValue) => {
          this.maonclick1(newValue)
        }} 
      />
     
    </Box></p>
		 </div>
		 <div className="clear"></div>
	<div className="feedbackcollft"><p>Professionalism </p></div>
<div className="feedbackcollrt"><p><Box
      sx={{
        '& > legend': { mt: 2 },
      }} class="inputrating"
    >
      
      <Rating
        name="maprofesrating"
        value={this.state.maprofesrating}
        onChange={(event, newValue) => {
          this.maonclick2(newValue)
        }} 
      />
     
    </Box></p></div>
		 <div className="clear"></div>
		 
<div className="feedbackcollft"><p>Creativity </p></div>
<div className="feedbackcollrt"><p> <Box
      sx={{
        '& > legend': { mt: 2 },
      }} class="inputrating"
    >
      
      <Rating
        name="macrativityrating"
        value={this.state.macrativityrating}
        onChange={(event, newValue) => {
          this.maonclick3(newValue)
        }} 
      />
     
    </Box></p>
		 </div>
		 <div className="clear"></div>
	
	
	</div>
		<div className="feedbackright">
		
		<div className="feedbackcollft"><p>Value For Money  </p></div>
<div className="feedbackcollrt"><p><Box
      sx={{
        '& > legend': { mt: 2 },
      }} class="inputrating"
    >
      
      <Rating
        name="mavalmoneyrating"
        value={this.state.mavalmoneyrating}
        onChange={(event, newValue) => {
          this.maonclick4(newValue)
        }} 
      />
     
    </Box></p>
		 </div>
		 <div className="clear"></div>
<div className="feedbackcollft"><p>Overall Experience  </p></div>
<div className="feedbackcollrt"><p><Box
      sx={{
        '& > legend': { mt: 2 },
      }} class="inputrating"
    >
      
      <Rating
        name="maexperrating"
        value={this.state.maexperrating}
        onChange={(event, newValue) => {
          this.maonclick5(newValue)
        }} 
      />
     
    </Box></p>
		 </div>
		 <div className="clear"></div>
	
	<div className="feedbackcollft"><p>Other Feedback</p></div>
	<div className="feedbackcollrt"><p><TextField name="maotherfeedback" 
	   onChange={this.myChangeHandler} 
	   fullWidth
	   multiline
          rows={2}
	   value={this.state.maotherfeedback}
			 
			  ref={this.usernameInput}
			  inputProps={{tabIndex: "4",maxLength:150}} 
	   InputLabelProps={{ shrink: true }}
                          /></p></div>		
	<div className="clear"></div>
</div>		
	<div className="clear"></div>
		<DialogActions>
		 
		<div className="actionbtns">

		
		<div className="delbtns">
		<Button onClick={this.handleback5} className="delbttn">
		Back
		</Button>
		</div>
		<div className="savebtns">
		<Button onClick={this.handleNext5} className="savebttn">
		{this.state.secondarytext}
		</Button>
		</div>
		<div className="clear"></div>
		</div>
		</DialogActions>
		</div>
		</React.Fragment>)}	 
		
		
		
		</form>
		</React.Fragment>
	);
	}
}
	