import React, { Component } from 'react';
import { Slot } from 'react-page-layout';
import './styles.css';
import GroupIcon from '@mui/icons-material/Group';
import InventoryIcon from '@mui/icons-material/Inventory';
import ReviewsIcon from '@mui/icons-material/Reviews';
import PersonIcon from '@mui/icons-material/Person';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CssBaseline from '@mui/material/CssBaseline';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { makeStyles } from '@mui/styles'; 
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
const useStyles = makeStyles((theme) => ({ 
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  row:{border:0},
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  }, 

heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },  
  
}));
	

 class Displaymenu extends Component {
	
	constructor(props) {
    super();
	
	 this.state = {menudata:[],paydata:[],logredirect:false,pagename:'',order:'desc',comments:'',psid:0 ,orderBy:'ps_id',page: 0 ,prevpage:0,totaldata:0,rowsPerPage:10,rowsPaging:[],redirect:false,vredirect:false
	,isExpanded:'',expanded:false,setExpanded:'',panel:'',anchorEl:null,menuopen:null,}
	
	 this.updateDonenewParent = this.updateDonenewParent;
	}
		
updateDonenewParent = (text) => {this.componentDidMount();	}


componentDidMount() {
	


	}

	
	render() {
 const {onToggle, setCollapsibleElement, progress } = this.state;
    // const {expanded, setExpanded} = this.state;
  
		return ( 
			<div>
				
<div className="mastermain">
  
 {(localStorage.getItem("YSrole") === "1" || localStorage.getItem("YSrole") === "2") && (
<a href={"#/User"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="User">
 <IconButton aria-label="User">
 <GroupIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="User" />
 </ListItem></a>
 
 )} 

  
  {(localStorage.getItem("YSrole") === "1" || localStorage.getItem("YSrole") === "2") && ( 
	<a href={"#/Staff"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Staff">
 <IconButton aria-label="Staff">
 <AccountBoxIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Staff" />
          </ListItem></a>	  
		  
		  

 )}

 
   
   {(localStorage.getItem("YSrole") === "1" || localStorage.getItem("YSrole") === "2") && (

<a href={"#/Vendor"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Vendor">
 <IconButton aria-label="Vendor">
 <PersonIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Vendor" />
          </ListItem></a>

   )}
   
   {(localStorage.getItem("YSrole") === "1" || localStorage.getItem("YSrole") === "2") && (

<a href={"#/Feedback"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Feedback">
 <IconButton aria-label="Feedback">
 <ReviewsIcon /> 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Feedback" />
          </ListItem></a>	

   )}
   
   {(localStorage.getItem("YSrole") === "1" || localStorage.getItem("YSrole") === "2") && (

<a href={"#/Customermonthlyreport"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Customer Monthly Report">
 <IconButton aria-label="Customer Monthly Report">
 <ReviewsIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Customer Monthly Report" />
          </ListItem></a>

   )}
   
   {(localStorage.getItem("YSrole") === "1" || localStorage.getItem("YSrole") === "2") && (
   
<a href={"#/Vendorreport"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title=" Vendor Report">
 <IconButton aria-label=" Vendor Report">
 <ReviewsIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary=" Vendor Report" />
          </ListItem></a>

   )}
   
   
    {localStorage.getItem("YSrole") === "3" && (
  
  <a href={"#/Feedback"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Feedback">
 <IconButton aria-label="Feedback">
 <ReviewsIcon /> 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Feedback" />
          </ListItem></a>	
  
  )}
  
   
   
   




</div>
				
				

</div>
		);
		
		
	}
}

export default Displaymenu;