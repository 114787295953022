import { BrowserRouter as Router, Route, HashRouter,Routes,NavLink,Outlet,Outlet as head } from 'react-router-dom';
import React ,{Component} from 'react';
import ReactDOM from "react-dom";
import { Navigate  } from 'react-router-dom';
import Layouthead from "./components/Layouthead";
import Login from "./components/Login";
import User from "./components/User";
import Staff from "./components/Staff";
import Vendor from "./components/Vendor";
import Package from "./components/Package";
import './components/styles.css';
import Feedback from "./components/Feedback";
import Feedbackthankyou from "./components/Feedbackthankyou";
import Customermonthlyreport from "./components/Customermonthlyreport";
import Vendorreport from "./components/Vendorreport";
import Changepassword from './components/Changepassword';
import Customerreportviewmore from './components/Customerreportviewmore';
import Logout from './components/Logout';
class App extends Component {
	
	
  render() {
    return (
	
	
	
	<HashRouter basename="/">

	
	
	
	  <Routes>
	   <Route path="/Login" element={<Login />} />  
	 <Route element={<Layouthead />}>
	  
	   <Route path="/User" element={<User />} />  
	   <Route path="/Staff" element={<Staff />} />  
	   <Route path="/Vendor" element={<Vendor />} /> 
	    <Route path="/Package" element={<Package />} /> 
		<Route path="/Feedback" element={<Feedback />} /> 
		<Route path="/Feedbackthankyou" element={<Feedbackthankyou />} />		
		<Route path="/Changepassword" element={<Changepassword />} /> 
		<Route path="/Customermonthlyreport" element={<Customermonthlyreport />} /> 
		<Route path="/Vendorreport" element={<Vendorreport />} /> 
		<Route path="/Customerreportviewmore" element={<Customerreportviewmore />} /> 
		
		<Route path="/Logout" element={<Logout />} /> 
	   </Route>
	   <Route exact path="/"  element={<Navigate to="/Login" />}  />
	  </Routes>
	  
	
	  

	
	 </HashRouter>
	
	
    );
  }
}

export default App;
