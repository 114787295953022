import { BrowserRouter as Router, Route, Routes,NavLink,Outlet } from 'react-router-dom';
import React ,{Component} from 'react';
import ReactDOM from "react-dom";
import Serverurl from './Apiurl';
import axios from 'axios';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import TableSortLabel from '@mui/material/TableSortLabel';
import { makeStyles } from '@mui/styles';  

import { Navigate  } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';


const headCells = [

  { id: 'pa_name', numeric: false, label: 'Package Name' },
  { id: 'pa_cost', numeric: false, label: 'Cost' },
  { id: 'pa_description', numeric: false, label: 'Description' },
  { id: 'pa_type', numeric: false, label: 'Type' },
   { id: '',  label: '' },
  ]
 function stableSort(array, cmp) {
	  const stabilizedThis = array.map((el, index) => [el, index]);
	  stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	  });
	  return stabilizedThis.map(el => el[0]);
}
function desc(a, b, orderBy) {
	  if (b[orderBy] < a[orderBy]) {
		return -1;
	  }
	  if (b[orderBy] > a[orderBy]) {
		return 1;
	  }
	  return 0;
}
function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
 
function EnhancedTableHead(props) {
  const { classes,  order, orderBy,  rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  };

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 
 icon: {
   verticalalign:'middle',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});



export default class packagess extends Component {
	

	 constructor(props) {
    super(props);
   
      this.state = {redirect:false,
      Packagesdata:[],cost:'',name:'',description:'',page: 0 ,prevpage:0,totaldata:0,rowsPerPage:10,rowsPaging:[],
	  order:'desc' ,orderby:'pa_id',title:'',type:'',unit:'',search:'',
	          
      }
       this.updateDonenewParent = this.updateDonenewParent;
	   
	}
	
	updateDonenewParent = (text) => {this.componentDidMount();  }
	componentDidUpdate() 
	{
		
		  if(this.state.prevpage !== this.state.page) {
			this.setState({   prevpage: this.state.page});
		  this.apicall(this.state.page, localStorage.getItem("page"));  
		  }
	}
	
	componentDidMount() 
	{  
	
			if(localStorage.getItem("YSuserid")==null)
			{

				   this.setState({
					redirect: true,
					pagename:'Login'
				  })
			}
			
			document.title = "Yashaswi Silks - Packages Management";
			
			
			  localStorage.setItem("page",this.state.rowsPerPage)
		  this.apicall(this.state.page,this.state.rowsPerPage);
		    //this.onloaduserbind();
 
    } 
	
	/*onloaduserbind()
	{

    axios.get(Serverurl+"ajaxpackage.php?action=getall")
	
    
      .then((response) => {    
     
      this.setState({  Packagesdata: response.data,  });
      })   
    
      .then(data => {
        let userFromApi = this.state.Packagesdata.map(user => {
                            
          return {value: user.ur_id, display:user.ur_name}
        });
      
		
        this.setState({
          Packagesdata: [{value: '0', display: 'Select User'}].concat(userFromApi)
        });
      })
  
  
  }
  */
	
	handleClickSearch=()=>{    
	
				let name = this.state.name;							
				let cost = this.state.cost;
				let description = this.state.description;
				let type = this.state.type;
				
			this.apicall(this.state.page,this.state.rowsPerPage); 	
	} 
	
	
	myChangeHandler = (event) => 
	{
	  let nam = event.target.name;
	  let val = event.target.value;
	  this.setState({[nam]: val});
	 
	}
	apicall(page,rowsPerPage){
		      
			  axios.get(Serverurl+'ajaxpackage.php?action=getall&pa_name='+this.state.name+'&pa_type='+this.state.type+'&StartIndex='+page+'&PageSize='+rowsPerPage)
     
		      
			  //axios.get(Serverurl+'ajaxpackage.php?action=getall&pa_name'+this.state.name+'&pa_type'+this.state.type+'&Sorting='+this.state.orderby+' '+this.state.order+'&StartIndex='+page+'&PageSize='+rowsPerPage)
	  .then(resp => {  
			
		if(resp.data.length>0){
			
				this.setState({  
				  Packagesdata: resp.data,  
				   totaldata:parseInt(resp.data[0].count),rowsPaging:[],
				  
				});
				
				let copy=[10,20,30,40,50];
				/*let cnt=this.state.totaldata;
				let page=5;
				 cnt=Number(cnt)/Number(page);
			
			
				for (var key=0;key<cnt;key++) { 
				
					let val=(Number(key)*5)+5;
					
				 copy.push( val)
				}*/
				
		  
		  this.setState({rowsPaging:copy  });
		 
		 }
		 
		 else
		 {
			   this.setState({Packagesdata:[],totaldata:0});
		 }
	  //alert(this.state.prevdis_icon);
		});
	}
	handleSubmit= (event) => {
    
		event.preventDefault();

		let name = this.state.name;
		let cost = this.state.cost;
		let type = this.state.type;
		let description = this.state.description;
		
		this.apicall(this.state.page,this.state.rowsPerPage);
		
	
     } 
	 handleRequestSort = (event, property) => 
	 {
		  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
		  

		  this.setState({   orderBy: property,
			order:isAsc  ? 'desc' : 'asc'
		   
		  });
	 };
	 
	 handleChangePage = (event, newPage) => 
	 {     
			let pageval = localStorage.getuser("page");
			this.setState({  
			  page: newPage,
			  rowsPerPage:parseInt(pageval)
			});
		   
		 
			setTimeout(() =>  this.componentDidUpdate() ,500); 
	  }; 
	  
	handleChangeRowsPerPage = event => 
	{     
			let pageval = event.target.value;
			localStorage.setuser("page",pageval)
			setTimeout(() =>  this.componentDidUpdate() ,500);
			this.setState({  
			  page: 0,
			  rowsPerPage:parseInt(pageval),
			  prevpage:-1
			}); 
	}; 
	
	PackageDelete(paid)
	{  
	
		let r = window.confirm("Do you really want to Delete?");
		if(r === true){
			//sign Out logic
		var formData = new FormData();
		formData.append('action','delete')
		formData.append('pa_id',paid)
		formData.append('userid',1)
			
			 axios.post(Serverurl+"ajaxpackage.php", formData, {
				}).then(res => {
			
					 this.setState({  
			  prevpage: -1,
			
			});
		   
		 
			setTimeout(() =>  this.componentDidUpdate() ,500); 
		  
			
					});
		 }
    }
	
	Packageaddbox(val){
        this.setState({ paid: val });  
  
         setTimeout(() => this.refs.AddPackagebox.handleClickOpen(),500);

      }
	  
	  
	render() {
		
		if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }
	
		return (
		
		
		<React.Fragment>
   
		<div className="containermain">
		<div className="containerbg">
			<div className="pageheader">
				<h1>Package Management</h1>
			</div>

			<div className="searchmaster">
			<div className="searchpart">
			<ul>	
				<li>
				 <p ><TextField
							
							margin="normal"
							
							value={this.state.name}
							size="small"
						   inputProps={{tabIndex: "1",maxLength:150}}
							placeholder="Name"
							name="name" onChange={this.myChangeHandler}
						
						  /></p>
				</li>
				<li>
					<p><FormControl  className="stadrp">			   
						<Select
						
						native
						value={this.state.type}
						onChange={this.myChangeHandler}
						name='type'
						id='type'
						placeholder="Type"
						InputLabelProps={{
						shrink: true,
						}}
						>
						
						<option value={0}>Select Type</option>
						<option value={1}>Decor</option>	
						<option value={2}>Catering</option>	
						
						<option value={3}>Photograpgy</option>	
						<option value={4}>Makeup Artiest</option>
						
							
						</Select>
						</FormControl>
					</p>	
				</li>
		</ul>
		</div>
		
	
		<div className="searchnewbtn">
		<ul>
			<li>
				<div className="searchbutton">
					<Button variant="Text" onClick={this.handleClickSearch} className={useStyles.submit} >
						Search
					</Button>	
			   </div>
		   </li>
		   <li>
			  <div className="addnewbtn">
			  <Button variant="Text" onClick={()=>{this.Packageaddbox(0)}} className="pointerbtn" >
				New
			  </Button>
			  </div>
			</li>
		</ul>
		</div>	
		<div class="clear"></div>
		
		
</div>		



<AddPackagebox ref="AddPackagebox" id={this.state.paid}  updateDonenew={this.updateDonenewParent}/>
<Paper className={useStyles.root}>


  <TableContainer className={useStyles.container}>  
                <Table stickyHeader aria-label="sticky table">  
<TableHead> 
<TableRow>
                                              
                       
						
                      </TableRow></TableHead>
					  
					   <EnhancedTableHead           
              order={this.state.order}
              orderBy={this.state.orderBy}
              
              onRequestSort={this.handleRequestSort}
              rowCount={this.state.Packagesdata.length}
            />
                      <TableBody>
                     {stableSort(this.state.Packagesdata, 
			getSorting(this.state.order,
			this.state.orderBy)).map((user,index) => {  
           return (
 <React.Fragment>
 <TableRow key={index} >



<TableCell>{user.pa_name}</TableCell>
<TableCell>{user.pa_cost}</TableCell>
<TableCell>{user.pa_description}</TableCell>
<TableCell>{user.type}</TableCell>

<TableCell align="left">
<div className="actionbtns">

 <IconButton aria-label="edit" className="frmPrimarybtns" onClick={()=>{this.Packageaddbox(user.pa_id)}}>
     <EditIcon />
 </IconButton>

<React.Fragment> 
  <IconButton aria-label="delete" className="admsndisablebtn" onClick={()=>{this.PackageDelete(user.pa_id)}}>
        <DeleteIcon />
  </IconButton>

 </React.Fragment>
 
 </div>

 
 </TableCell>
 </TableRow>

 </React.Fragment>	
);
					})}
					
					{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colspan="4">No Records</TableCell>
					</TableRow>
					) }
					
                      </TableBody>


</Table>
  </TableContainer> 
  <TablePagination  
        rowsPerPageOptions={[10, 20, 30, 40, 50]}  
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onChangePage={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      />   
  </Paper>
  </div>  
</div>
 </React.Fragment> 

 
 
  );
}
}

export  class  AddPackagebox extends Component {

	   constructor(props) 
	   {
		  super(props)
		  
		  this.state = {
			  
			   open:false,description:'',name:'',cost:'',pa_id:0,errormessage:'',
			   erropen:false,type:'',
	   
		  
		  }
		  
	   }
  
	Packagegetdata() 
    {
	
	  if(this.props.id!==0)
	  {
		  
			axios.get(Serverurl+'ajaxpackage.php?action=getall&pa_id='+this.props.id)
		  .then(resp => {  
				
			if(resp.data.length>0)
			{
				this.setState({ 
				 
				  pa_id:resp.data[0].pa_id,
				  description: resp.data[0].pa_description,	  
				  name: resp.data[0].pa_name,
				  cost: resp.data[0].pa_cost, 
					type: resp.data[0].pa_type, 
				
				});
			//alert(this.state.prevca_icon);
		//alert(this.state.)
		  }
		  
			});
			  
	   }
	   else
	   {
			 this.setState({  
					description:'',			 
					name:'',
					cost:'',			  
					pa_id:0,
					type:''
			  }); 
	   }
  
    }	   
	handleClickOpen = (e) => 
	{
	 
	    this.setState({
						open:!this.state.open, 
						description:'',			 
						name:'',
						cost:'',			  
						type:'',
						errormessage:'',
						erropen:false,
						
		});
	    this.Packagegetdata();


	};
	myChangeHandler = (event) => 
	{
		
		const re = /^[0-9\b]+$/;
		//const re = /^[a-zA-Z0-9_]*$/;
		//const re = /^\d{0,8}(\.\d{1,4})?$^[-A-z \t]/;
		
		let nam = event.target.name;
		let val = event.target.value;
		this.setState({[nam]: val});
		
	  
		if(nam==="cost" )
		{
			if (event.target.value === '' || re.test(event.target.value)) 
			{
				this.setState({[nam]: val}); 		  
			}
		}
		else
		{
			this.setState({[nam]: val});
		}

	}



	handleClose = (e) => 
	{	 
	
	
	
	
		 let description=this.state.description;
		 let cost=this.state.cost;
		 let name=this.state.name;
		 let type=this.state.type;
		
		 
		 if(description==="")
		{			
			this.setState({errormessage:"Please Enter description",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			
		}	
		if(cost==="")
		{
			this.setState({errormessage:"Please Enter cost",erropen:true});
				 window.scrollTo({
				  top: 0
			  })
				 return false;
			
		}
		
		if(name==="")
		{			
			this.setState({errormessage:"Please Enter name",erropen:true});
				 window.scrollTo({
				  top: 0
			})
			return false;
		}
		 if(type==="")
		{			
			this.setState({errormessage:"Please Enter type",erropen:true});
			window.scrollTo({
				  top: 0
			  })
			return false;
			
		}			 
			 
		
		
		var formData = new FormData();
		
		if(this.state.pa_id===0)
		{
			formData.append('pa_id',0) 
				  
		}
		else
		{
				  
			formData.append('pa_id',this.state.pa_id)
				
		}
			 
		formData.append('action','insert') 
		formData.append('pa_cost',cost)		
		formData.append('pa_description',description)
		formData.append('pa_name',name)
		formData.append('pa_type',type)
	
		formData.append('userid',1)
		
		//formData.append('userid',localStorage.getuser("YSuserid"))				  
		
		axios.post(Serverurl+"ajaxpackage.php", formData, {
				}).then(res => {

					if(res.data == -1)
					{
						this.setState({errormessage:"Package with the same name already exist",erropen:true,open:true});
						 window.scrollTo({
						  top: 0
						})
						 return false;	
					}
					this.props.updateDonenew();
			
				});
		  
				this.setState({ open:false });
	};



	 handleErrorclose=(event)=>{
	  
		this.setState({erropen: false});  
	 } 
render (){

return(

		<React.Fragment>

			<Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title"> Package Management </DialogTitle>
			<DialogContent>
			  
			<p> 
				<Collapse in={this.state.erropen}>
				<Alert
				  action={
					<IconButton
					  aria-label="close"
					  color="inherit"
					  size="small"
					  onClick={() => {
					   this.handleErrorclose();
					  }}
					>
					  <CloseIcon fontSize="inherit" />
					</IconButton>
				  } severity="error"
				>
				{this.state.errormessage}
				</Alert>
			  </Collapse>
			</p>
			
				
			<p> <span className="validationtxt">*</span>Name</p>
			
			<p>
				<TextField name="name" 
	   onChange={this.myChangeHandler} 
	   fullWidth
	   size="small"
	   value={this.state.name}
			 
			  ref={this.usernameInput}
			  inputProps={{tabIndex: "1",maxLength:150}} 
	   InputLabelProps={{ shrink: true }}
                          />
			</p>
			<p> <span className="validationtxt">*</span>Cost</p>
			
				<p> <TextField name="cost" 
	   onChange={this.myChangeHandler} 
	   fullWidth
	   size="small"
	   value={this.state.cost}
			 
			  ref={this.usernameInput}
			  inputProps={{tabIndex: "2",maxLength:13}} 
	   InputLabelProps={{ shrink: true }}
                          />
			</p>
			<p> <span className="validationtxt">*</span>Description</p>
			
			<p>	<TextField name="description" 
	   onChange={this.myChangeHandler} 
	   multiline
	    rows={4}
	   fullWidth
	   
	   value={this.state.description}
			 
			  ref={this.usernameInput}
			  inputProps={{tabIndex: "3",maxLength:150}} 
	   InputLabelProps={{ shrink: true }}
                          />
			</p>
			
			
					<p><span className="validation">*</span> Type</p>
				<p><FormControl  className="stadrp">			   
				<Select
				variant="outlined"
				native
				value={this.state.type}
				onChange={this.myChangeHandler}
				name='type'
				id='type'
				InputLabelProps={{
				shrink: true,
				}}
				>
				
				<option value={0}>Select Type</option>
				<option value={1}>Decor</option>
				<option value={2}>Catering</option>	
				<option value={3}>Photograpgy</option>	
				<option value={4}>Makeup</option>	
				</Select>
				</FormControl></p>

			  </DialogContent>
			  <DialogActions>
			  <div className="actionbtns">

		<ul>
			<li>
				<div className="delbtn">
			  
					<Button variant="Text" onClick={this.handleClickOpen}>
					  Cancel
					</Button>
					
				</div> 
			</li>
		 
		 &nbsp;  &nbsp;
			<li>
			<div className="editbtn">
					<Button variant="Text" onClick={this.handleClose}>
					  Save
					</Button>
					</div>
			</li>
		 </ul>
		 </div>
			  </DialogActions>
			</Dialog>
		</React.Fragment>

		) 
	}
		 
} 
 