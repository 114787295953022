import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Navigate  } from 'react-router-dom';



export default class Logout extends Component {
	 constructor(props) {
    super(props);
	 this.state = {redirect:false,pagename:''}
	 }
	componentDidMount() {
		var strusername="",strpd="";
		if(localStorage.getItem("YSpassword")!=null)
	{
		strusername=localStorage.getItem("YSusername");
		strpd=localStorage.getItem("YSpassword")
	}
		localStorage.clear();
		 localStorage.setItem("YSusername",strusername);
	  localStorage.setItem("YSpassword",strpd);
		this.setState({
							redirect: true,
							pagename:'/Login'
						  }) 
	}
	render() {
		if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }
return (
<React.Fragment>
</React.Fragment>
)
	}
}



