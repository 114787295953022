import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Navigate  } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Serverurl from './Apiurl';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import logo from './images/logo.png';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';


export default class Login extends Component {
	
	 constructor(props) {
    super(props);
	 this.state = {Username:'',Password:'',btndisabled:false,pagename:'',errormessage:'',open:false,checked:true,showPassword:false}
	 }
myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val}); 
} 
handleChange = (event) => 
{
	this.setState({checked: !this.state.checked});
};
handleClickShowPassword = (event) => 
{
	this.setState({showPassword: !this.state.showPassword});
};
componentDidMount() {
document.title = "Yashaswi Silks - Login";

if(localStorage.getItem("YSpassword")!=null)
	{
		 this.setState({
            Username: localStorage.getItem("YSusername"),
			Password:localStorage.getItem("YSpassword"),
          })
	}
	if(localStorage.getItem("YSuserid")!==null)
	{
		if(localStorage.getItem("YSrole")!=="1")
		{ 	
				this.setState({
							redirect: true,
							pagename:'/User'
						  }) 
		}else{
			this.setState({
							redirect: true,
							pagename:'/User'
						  }) 
		}
	}

}
handleClickLogin= (event) => 
		{
		event.preventDefault();
				
				let Username = this.state.Username;
				let Password = this.state.Password;
				let ischeck = this.state.checked;	
				if(Username==''){
						
				this.setState({errormessage:"Please Enter Username",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
			
				if(Password==''){
						this.setState({errormessage:"Please Enter Password",open:true});
						 window.scrollTo({        top: 0     })
						 return false; 
					
				}
var formData = new FormData();
				formData.append('action','userlogin') 	
 
				formData.append('ur_username',Username)
				formData.append('ur_password',Password)
				
	
	
				axios.post(Serverurl+"ajaxuser.php", formData, {
				}).then(res => {
				
				
				if(res.data.length>0){
				localStorage.setItem('YSusername',res.data[0].ur_name);				
				localStorage.setItem('Ysname',res.data[0].ur_name);				
							
				localStorage.setItem('YSrole',res.data[0].ur_role);	
				localStorage.setItem('YSuserid',res.data[0].ur_id);	
	if(ischeck==true)
  {
	   localStorage.setItem("YSusername",this.state.Username);
	  localStorage.setItem("YSpassword",this.state.Password);
	 
	
  }else{
localStorage.removeItem("YSusername");
	  localStorage.removeItem("YSpassword"); 
  }
				
				if(localStorage.getItem("YSrole")!=="1")
		{ 	
				this.setState({
							redirect: true,open:false,
							pagename:'/Feedback'
						  }) 
		}else{
			this.setState({
							redirect: true,open:false,
							pagename:'/User'
						  }) 
		}
				
				}
				else
				{
					
 

		  
 
					this.setState({errormessage:"Not a valid login details",open:true});
		 window.scrollTo({        top: 0     })
				}
				
				});
				
				
			
		}
				
	 handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }
	render() {
		if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }
return (
<React.Fragment>

<div className="mainscreen-rows">
<div className="main">
	<div className="leftbandlog-cols">
		
		<div className="loglogo">
			<img src={logo}  />
		</div>
		
	</div>
	<div className="contentseclog-cols">
	
		<div className="logindiv">
		<h1>Log In</h1>
			 <p>  <Collapse in={this.state.open}>
							<Alert
							  action={
								<IconButton
								  aria-label="close"
								  color="inherit"
								  size="small"
								  onClick={() => {
								   this.handleErrorclose();
								  }}
								>
								  <CloseIcon fontSize="inherit" />
								</IconButton>
							  } severity="error"
							>
							{this.state.errormessage}
							</Alert>
					</Collapse></p>
					
					
			<p><TextField id="txtUsername" fullWidth name="Username" value={this.state.Username}  inputProps={{tabIndex: "1",maxLength:150}} onChange={this.myChangeHandler}
			InputLabelProps={{shrink: true}} placeholder="Username"/></p>
			<p>
			<TextField
            variant="outlined"
			className="bordercolor"
            margin="normal"
           inputProps={{ tabIndex: "2",maxLength:150 }} 
            fullWidth
			value={this.state.Password}
             type={this.state.showPassword ? 'text' : 'password'}
            id="txtPassword"
			placeholder="Password"
            name="Password" onChange={this.myChangeHandler}
            	 InputProps={{
          endAdornment: (
            <InputAdornment position="end" className="iconcolor" >
              <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.handleClickShowPassword}
                
                >
                  {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </InputAdornment>
          ),
        }}
		/>
			 
</p>
			<p> <FormControlLabel
        label="Remember Password"
        control={ <Checkbox value="remember" inputProps={{ tabIndex: "3" }} checked={this.state.checked}  onChange={this.handleChange}  inputProps={{ 'aria-label': 'controlled' }} />}
      /></p>
			<p className="btnsubmits"><Button
            type="submit"
            fullWidth
            variant="text" 
            color="primary"
            className="frmPrimarybtn"
			onClick={this.handleClickLogin}
			
          >
            Login
          </Button></p>
		</div>
	</div>
	
	<div className="clear"></div>
</div>
</div>
</React.Fragment>
)

	}		
}







