import React, { Component } from "react";



//const Serverurl= ["http://localhost/xampp/Projects/yashaswifeedback/"]
//const Serverurl= ["http://localhost/xampp/yashaswisilks/"]
const Serverurl= ["https://yashaswifeedback.globalbuzz.in/appfiles/"]


export default Serverurl;






