import { BrowserRouter as Router, Route, Routes,NavLink,Outlet } from 'react-router-dom';
import React ,{Component} from 'react';
import ReactDOM from "react-dom";
import Serverurl from './Apiurl';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import TableSortLabel from '@mui/material/TableSortLabel';
import { makeStyles } from '@mui/styles';  

import { Navigate  } from 'react-router-dom';


import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

import Grid from '@mui/material/Grid';
import DateFnsUtils from '@date-io/date-fns';
import Moment from 'react-moment';
import moment from 'moment';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,DatePicker, 
  KeyboardDatePicker,
  DateTimePicker
} from '@material-ui/pickers';

import { addDays } from "date-fns";
import ReactToPrint from 'react-to-print';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';



const headCells = [

  { id: 'feedback_type', numeric: false, label: 'Type' },
  { id: 'fb_communication', numeric: false, label: 'Communication' },
  { id: 'fb_executiontime', numeric: false, label: 'Execition Time' },
  { id: 'fb_quality', numeric: false, label: 'Quality' },
  { id: 'fb_outout', numeric: false, label: 'Output' },
  { id: 'fb_valueformoney', numeric: false, label: 'Value for Money' },
  { id: 'fb_overallexperience', numeric: false, label: 'Overall Experience' },
 
  { id: '',  label: '' },
  ]
 function stableSort(array, cmp) {
	  const stabilizedThis = array.map((el, index) => [el, index]);
	  stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	  });
	  return stabilizedThis.map(el => el[0]);
}
function desc(a, b, orderBy) {
	  if (b[orderBy] < a[orderBy]) {
		return -1;
	  }
	  if (b[orderBy] > a[orderBy]) {
		return 1;
	  }
	  return 0;
}
function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
 
function EnhancedTableHead(props) {
  const { classes,  order, orderBy,  rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  };

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 
 icon: {
   verticalalign:'middle',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});
export default class Vendorreport extends Component {
	
	
			constructor(props) {
    super(props);	
   
      this.state = {redirect:false,
      Customerdata:[],cuid:'',page: 0 ,prevpage:0,totaldata:0,rowsPerPage:100,rowsPaging:[],
	  order:'desc' ,orderby:'cu_id',cu_id:'',search:'',id:'',type:''	   
			}
      
	  };
 getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}	  

myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;  
  this.setState({[nam]: val});

 } 
 


 componentDidUpdate() {
		
  if(this.state.prevpage !== this.state.page) {
    this.setState({   prevpage: this.state.page});
  this.apicall(this.state.page, localStorage.getItem("page"));  
  }
}


componentDidMount() {
	
	if(localStorage.getItem("YSuserid")==null)
			{

				   this.setState({
					redirect: true,
					pagename:'Login'
				  })
			}
			
			document.title = "Yashaswi Silks - Customer Report";
			
			
			  localStorage.setItem("page",this.state.rowsPerPage)
		  this.apicall(this.state.page,this.state.rowsPerPage);

}



apicall(page,rowsPerPage){
	
	
	let search = window.location.href;
let cuid = this.getParameterByName('cuid',search);	
	
  	
	axios.get(Serverurl+'ajaxmonthlycustomerreport.php?&cu_id='+cuid+'&StartIndex='+page+'&PageSize='+rowsPerPage)
  	
	
  .then(resp => {  
		
    if(resp.data.length>0){
		let copy=[10,50,100,150];
			this.setState({  
			  Customerdata: resp.data,  			  
			   totaldata:parseInt(resp.data[0].count),
			   rowsPaging:copy,
			   type:resp.data[0].feedback_type,
			  
	
			});
			
			
			 
     }else{this.setState({  
	 Customerdata:[],totaldata:0});
	 }
  //this.exportapicall();
    });
	

	
}


handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};

handleChangePage = (event, newPage) => {  
   
     
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage, prevpage:-1,
      rowsPerPage:parseInt(pageval)
    });
   
 
    setTimeout(() =>  this.componentDidUpdate() ,500); 
  };  
  
  handleChangeRowsPerPage = event => {  
   
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)
    setTimeout(() =>  this.componentDidUpdate() ,500);
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    });  
  
};

render() {
				
				if (this.state.redirect === true) {
			  return <Navigate to={this.state.pagename} />
			}
			
		return (
		
					
			<React.Fragment>
			
			<div className="containermain">
		<div className="containerbg">
 <div className="backbutton">
	<a href="#/Customermonthlyreport"><p className="printreport">Back
		</p></a>
		</div>
		
			<div className="pageheader">
				<h1>Customer Report</h1>
			</div>


					<p className="searchbtn">
									  
			
<ReactToPrint
						trigger={() => <p id="print-barcode" className="printreport"> PRINT </p>}
						content={() => this.componentRef}/>
							
				   </p>
				
		   
			 
		
				
		
	
		
		
		
	
 <div ref={el => (this.componentRef = el)} style={{"margin-left":"0px","fontFamily":"Arial","margin-top":"10px","fontSize":"15px"}} >

<Paper className={useStyles.root}>
<TableContainer className={useStyles.container}>  
<Table stickyHeader aria-label="sticky table" id="vendorreport">  

			{this.state.Customerdata.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row,index) => {  
               		  
			   return ( 
                 <React.Fragment>
		<TableRow colSpan={1}> 
		
<TableCell colSpan={5} >
<p>Customer Name :<b> {row.cu_name}</b></p>
<p>Venue : <b>{row.venue}</b></p>

</TableCell >
<TableCell colSpan={3} align="right">
<p>Feedback Given By :<b> {row.cu_feedbackgivenby}</b></p>
<p>Feedback Taken By : <b>{row.st_name}</b></p>

</TableCell >
</TableRow>		
		<TableRow> 
<TableCell ><b>Type</b><br/><br/>Venue</TableCell>			
             <TableCell ><b>Feedback</b><br/><br/>{row.cu_venuefeedback}</TableCell>
			   <TableCell ><b>Cleanliness</b><br/><br/>{row.cu_cleanliness}</TableCell>			   
               <TableCell ><b>Service</b><br/><br/>{row.cu_service}</TableCell>  
               <TableCell ><b>Supervisor</b><br/><br/>{row.cu_superviser}</TableCell>             	   
               <TableCell ><b>Rooms</b><br/><br/>{row.cu_rooms}</TableCell>  
               <TableCell ><b>Overall Experience</b><br/><br/>{row.cu_overallexperience}</TableCell>
				<TableCell ><b>Comments</b><br/><br/>{row.cu_otherfeedback}</TableCell>			   
		   </TableRow> 
		 
  
		
       
		 {this.state.Customerdata[index].customerfeedback.map((roweach) => {


              return ( 
     
                  
<TableRow>
{roweach.fb_type=="1" && (<React.Fragment>
			 
          
			   <TableCell ><b>Type</b><br/><br/>{roweach.feedback_type}</TableCell>
			   <TableCell ><b>Package</b><br/><br/>{roweach.fb_pa_id}</TableCell>
			   <TableCell ><b>Communication</b><br/><br/>{roweach.fbcommunication}</TableCell>			   
               <TableCell ><b>Execution Time</b><br/><br/>{roweach.fbexecutiontime}</TableCell>  
               <TableCell ><b>Quality</b><br/><br/>{roweach.fbquality}</TableCell>             	   
               <TableCell ><b>Output</b><br/><br/>{roweach.fboutput}</TableCell>  
               <TableCell ><b>Value for Money</b><br/><br/>{roweach.fbvalueformoney}</TableCell> 
               <TableCell ><b>Overall Experience</b><br/><br/>{roweach.fb_overallexperience}</TableCell>
			<TableCell ><b>Comments</b><br/><br/>{roweach.fb_otherfeedback}</TableCell>				  
           
			</React.Fragment>)}
  {roweach.fb_type=="2" &&(<React.Fragment>
  
			 
          
			 <TableCell ><b>Type</b><br/><br/>{roweach.feedback_type}</TableCell>
			  <TableCell ><b>Package</b><br/><br/>{roweach.fb_pa_id}</TableCell>
			  <TableCell ><b>Communication</b><br/><br/>{roweach.fbcommunication}</TableCell>			   
               <TableCell ><b>Execution Time</b><br/><br/>{roweach.fbexecutiontime}</TableCell>  
               <TableCell ><b>Quality</b><br/><br/>{roweach.fbquality}</TableCell>             	   
               <TableCell ><b>Taste</b><br/><br/>{roweach.fboutput}</TableCell>  
               <TableCell ><b>Value for Money</b><br/><br/>{roweach.fbvalueformoney}</TableCell> 
               <TableCell ><b>Overall Experience</b><br/><br/>{roweach.fb_overallexperience}</TableCell>
				<TableCell ><b>Comments</b><br/><br/>{roweach.fb_otherfeedback}</TableCell> 			   
				 
              
               
         
			</React.Fragment>)}
			{(roweach.fb_type ==="3") &&(<React.Fragment>
  
			 
          
			   <TableCell ><b>Type</b><br/><br/>{roweach.feedback_type}</TableCell>
<TableCell ><b>Package</b><br/><br/>{roweach.fb_pa_id}</TableCell>			  
			  <TableCell ><b>Communication</b><br/><br/>{roweach.fbcommunication}</TableCell>			   
               <TableCell ><b>Execution Time</b><br/><br/>{roweach.fbexecutiontime}</TableCell>  
               <TableCell ><b>Professionalism</b><br/><br/>{roweach.fbquality}</TableCell>             	   
               <TableCell ><b>Creativity</b><br/><br/>{roweach.fboutput}</TableCell>  
               <TableCell ><b>Value for Money</b><br/><br/>{roweach.fbvalueformoney}</TableCell> 
               <TableCell ><b>Overall Experience</b><br/><br/>{roweach.fb_overallexperience}</TableCell>
				<TableCell ><b>Comments</b><br/><br/>{roweach.fb_otherfeedback}</TableCell> 			   
						   
				 
              
               
           
			</React.Fragment>)} 
			{roweach.fb_type =="4" &&(<React.Fragment>
  
			 
          
			   <TableCell >{roweach.feedback_type}</TableCell>
			   <TableCell >{roweach.fb_pa_id}</TableCell>
			   <TableCell >{roweach.fbcommunication}</TableCell>			   
               <TableCell >{roweach.fbexecutiontime}</TableCell>  
               <TableCell >{roweach.fbquality}</TableCell>             	   
               <TableCell >{roweach.fboutput}</TableCell>  
               <TableCell >{roweach.fbvalueformoney}</TableCell> 
               <TableCell >{roweach.fb_overallexperience}</TableCell>
				<TableCell>{roweach.fb_otherfeedback}</TableCell> 			   
						   
				 
              
               
           
			</React.Fragment>)} 
 
        
</TableRow>
		
		
		
		

        
              )})}	
			 
                 
		  
              </React.Fragment>   
              );  
           })}  
			
		
		{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colspan="2">No Records</TableCell>
					</TableRow>
					) }
			
     
        

  
  </Table>
  </TableContainer> 
  
   <TablePagination  
        rowsPerPageOptions={this.state.rowsPaging}  
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onChangePage={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      /> 
   
    </Paper>
</div>
</div></div>
 </React.Fragment> 

	
	 

	);
 }
} 

