import { BrowserRouter as Router, Route, Routes,NavLink,Outlet } from 'react-router-dom';
import React ,{Component} from 'react';
import ReactDOM from "react-dom";
import Serverurl from './Apiurl';
import axios from 'axios';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import TableSortLabel from '@mui/material/TableSortLabel';
import { makeStyles } from '@mui/styles';  

import { Navigate  } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

import Grid from '@mui/material/Grid';
import DateFnsUtils from '@date-io/date-fns';
import Moment from 'react-moment';
import moment from 'moment';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,DatePicker, 
  KeyboardDatePicker,
  DateTimePicker
} from '@material-ui/pickers';

import { addDays } from "date-fns";



import ReactToPrint from 'react-to-print';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';



const headCells = [

 { id: 'cu_name', numeric: false, label: 'Name' },
 { id: 'date', numeric: false, label: 'Date' },
  { id: 'venue', numeric: false, label: 'Venue' },
  { id: 'venuerating', numeric: false, label: 'Venue Rating' },
  { id: 'decorrating', numeric: false, label: 'Decor Rating' },
   { id: 'caterating', numeric: false, label: 'Cater Rating' },
  { id: 'photorating', numeric: false, label: 'Photography Rating' },
  { id: 'makeuprating', numeric: false, label: 'Makeup Artiest Rating' },  
  
  
  
  
 
  
 
   { id: '',  label: '' },
  ]
 function stableSort(array, cmp) {
	  const stabilizedThis = array.map((el, index) => [el, index]);
	  stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	  });
	  return stabilizedThis.map(el => el[0]);
}
function desc(a, b, orderBy) {
	  if (b[orderBy] < a[orderBy]) {
		return -1;
	  }
	  if (b[orderBy] > a[orderBy]) {
		return 1;
	  }
	  return 0;
}
function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
 
function EnhancedTableHead(props) {
  const { classes,  order, orderBy,  rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  };

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 
 icon: {
   verticalalign:'middle',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});
export default class customermonthlyreport extends Component {
	
	
			constructor(props) {
    super(props);
	
	
	
	
	var date = addDays(moment().toDate(),0);
   var month="",  month1="",date1="";
  if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate())		 
	 }else{date1=(date.getDate() )}
	 
	 
	    var strtodate = addDays(moment().toDate(),0);
   var strmonth="",  month1="",strdate1="";
  if((strtodate.getMonth() + 1)<10)
	 {
		strmonth="0"+ (strtodate.getMonth() + 1)		 
	 }else{strmonth=(strtodate.getMonth() + 1)}
	  if((strtodate.getDate() )<10)
	 {
		strdate1="0"+ (strtodate.getDate())		 
	 }else{strdate1=(strtodate.getDate() )}
	
	
	
	
	
	
   
      this.state = {redirect:false,
      Customerdata:[],cuid:'',name:'',page: 0 ,prevpage:0,totaldata:0,rowsPerPage:50,rowsPaging:[],
	  order:'desc' ,orderby:'cu_id',title:'',type:'',unit:'',search:'',fdate:'',tdate:'',venue:'',service:'',
	   createddate:'',id:'', exportData:[],

        date: addDays(moment().toDate(),0),
	fdate:addDays(moment().toDate(),0),tdate:addDays(moment().toDate(),0),
fdate1:date.getFullYear() + '-' + month + '-' +date1,tdate1:strtodate.getFullYear() + '-' + strmonth + '-' +strdate1,

	   
			}
      
	  }
	  
				 
	handfrChange = (date) => {
			 var month="",date1="";
			
			 if((date.getMonth() + 1)<10)
			 {
				month="0"+ (date.getMonth() + 1)		 
			 }else{month=(date.getMonth() + 1)}
			 
			 
			  if((date.getDate() )<10)
			 {
				date1="0"+ (date.getDate() )		 
			 }else{date1=(date.getDate())}
			 this.setState({ fdate:date,
		fdate1:date.getFullYear() + '-' + month + '-' +date1
			 })
			
			
	}	 
				 
	handtoChange = (date) => {
			  var month="",date1="";
			 
			 if((date.getMonth() + 1)<10)
			 {
				month="0"+ (date.getMonth() + 1)		 
			 }else{month=(date.getMonth() + 1)} 
			  if((date.getDate() )<10)
			 {
				date1="0"+ (date.getDate() )		 
			 }else{date1=(date.getDate())}
			  var strtotime=this.state.fdate1.split(' ');
			 
			if(strtotime[0]>date.getFullYear() + '-' + month + '-' +date.getDate())
			{
				 var strtoday=this.state.fdate1.split('-');
				 if(strtoday[0]>date.getFullYear() && strtoday[1]>month && strtoday[2]>date.getDate())
			{
			alert("Please Select To date greater than equal to")
			return false;
			}
			}
			 
			 this.setState({ tdate:date,
		tdate1:date.getFullYear() + '-' + month + '-' +date1
			 })
			
			
	}	  
			  
			  
	  
	  
	  
	  
	  componentDidUpdate() 
	  {
		
		  if(this.state.prevpage !== this.state.page) {
			this.setState({   prevpage: this.state.page});
		  this.apicall(this.state.page, localStorage.getItem("page"));  
		  }
	  }
	  
	  componentDidMount() 
	  {  
	
			if(localStorage.getItem("YSuserid")==null)
			{

				   this.setState({
					redirect: true,
					pagename:'Login'
				  })
			}
			
			document.title = "Yashaswi Silks - Customer Monthly Report";
			
			
			  localStorage.setItem("page",this.state.rowsPerPage)
		  this.apicall(this.state.page,this.state.rowsPerPage);
		  
		   
 
       } 
	   handleClickSearch=()=>{    
			
				let name = this.state.name;							
				let fromdate = this.state.fromdate;
				let todate = this.state.todate;
				let venue= this.state.venue;
				
			this.apicall(this.state.page,this.state.rowsPerPage); 	
	} 
	
	
	myChangeHandler = (event) => 
	{
	  let nam = event.target.name;
	  let val = event.target.value;
	  this.setState({[nam]: val});
	 
	}
	   
	   apicall(page,rowsPerPage){
		
		axios.get(Serverurl+'ajaxcustomer.php?action=customerreport&cu_name='+this.state.name+'&fromdate='+this.state.fdate1+'&todate='+this.state.tdate1+'&StartIndex='+page+'&PageSize='+rowsPerPage)
		
		
		
		 	  
	  .then(resp => {  
		console.log(resp.data);	
		if(resp.data.length>0){
			let copy=[50, 100, 150, 200, 250];;
				this.setState({  
				  Customerdata: resp.data,  
				   totaldata:parseInt(resp.data[0].count),createddate:resp.data[0].date,rowsPaging:copy
					
				});
				
			 }
		 
		 else
		 {
			   this.setState({Customerdata:[],totaldata:0});
		 }
	  //alert(this.state.prevdis_icon);
		});
		this.exportapicall();
	}
	
	exportapicall(){
		
			//axios.get(Serverurl+'billingreport.php?po_id='+this.state.poid+'&po_billno='+this.state.invoice+'&cu_contactname='+this.state.customer+'&fromdate='+this.state.fdate1+'&todate='+this.state.tdate1)
			
			
		axios.get(Serverurl+'ajaxcustomer.php?cu_name='+this.state.name+'&fromdate='+this.state.fdate1+'&todate='+this.state.tdate1)
		
	  
		 .then(resp => {  
			
		if(resp.data.length>0){
			
				this.setState({  
				  exportData: resp.data,  
				
				  
				   totaldata:parseInt(resp.data[0].count),
				   
				   
				   
				});
				
			
		 
		 }
	  
		});
		

		
	}

	
	handleSubmit= (event) => {
    
		event.preventDefault();

			let name = this.state.name;							
				let fromdate = this.state.fromdate;
				let todate = this.state.todate;
				let venue= this.state.venue;
				let service= this.state.service;
		
		this.apicall(this.state.page,this.state.rowsPerPage);
		
	
     } 
	 handleRequestSort = (event, property) => 
	 {
		  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
		  

		  this.setState({   orderBy: property,
			order:isAsc  ? 'desc' : 'asc'
		   
		  });
	 };
	 
	handleChangePage = (event, newPage) => {  

    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:this.state.rowsPerPage
    },()=>{ this.apicall(this.state.page,this.state.rowsPerPage);  });
   
 
    
  }; 
  
  handleChangeRowsPerPage = event => {  
  
    let pageval = event.target.value;
  

   
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    },()=>{ this.apicall(this.state.page,this.state.rowsPerPage); }); 
	
	
  };
	
	render() {
				
				if (this.state.redirect === true) {
			  return <Navigate to={this.state.pagename} />
			}
			
		return (
		
					
			<React.Fragment>
			
			<div className="containermain">
		<div className="containerbg">
   
		
			<div className="pageheader">
				<h1>Customer Monthly Report</h1>
			</div>

		<div className="searchmasters">
			
			<ul>	
				<li><p>Name :</p>
				<p><TextField
							
							margin="normal"
							fullWidth
							value={this.state.name}
							size="small"
						   inputProps={{tabIndex: "1",maxLength:150}}
							placeholder="Name"
							name="name" onChange={this.myChangeHandler}
						
						  />
				</p>
				</li>
				
							
				<li><p>From :</p>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
					
							  
	     <Grid container justify="space-around">
					  <DatePicker fullWidth
						value={this.state.fdate || addDays(moment().toDate(),1)}
						maxDate={addDays(moment().toDate(),0)}
						onChange={this.handfrChange}
							format="dd/MM/yyyy"
						autoOk name="fdate"
						ampm={false}
						variant="inline"
		inputVariant="outlined"
						id="borderdate"
					  />
					   </Grid>
				  
				 
				</MuiPickersUtilsProvider>
					</li>
					
					<li><p>To :</p>
					  <MuiPickersUtilsProvider utils={DateFnsUtils}>
					  <Grid container justify="space-around">
					  <DatePicker fullWidth
						value={this.state.tdate || addDays(moment().toDate(),1)}
						maxDate={addDays(moment().toDate(),0)}
						onChange={this.handtoChange}
							format="dd/MM/yyyy"
						autoOk name="tdate"
						ampm={false}
						variant="inline"
		inputVariant="outlined"
						id="borderdate"
					  />
					   </Grid>
					</MuiPickersUtilsProvider>
					
				</li>
	
				</ul>
		</div>
			
				
					<p className="searchbtton">
						<Button variant="Text" onClick={this.handleClickSearch} className="searchbttn">
							Search
						</Button>&nbsp;&nbsp;

					  
		  
				  
			 <ReactHTMLTableToExcel
							id="test-table-xls-button"
							className="expbtn"
							table="cusreport"
							filename="Customer report"
							sheet="Customer report"
							buttonText="EXPORT"/>&nbsp;&nbsp;
<ReactToPrint
						trigger={() => <p id="print-barcode" className="printreport"> PRINT </p>}
						content={() => this.componentRef}/>
							
				   </p>
				
		   
			 
		
				
		
	
		
		
		
	
 <div ref={el => (this.componentRef = el)} style={{"margin-left":"0px","fontFamily":"Arial","margin-top":"10px","fontSize":"15px"}} >

<Paper className={useStyles.root}>
<TableContainer className={useStyles.container}>  
                <Table stickyHeader aria-label="sticky table">  
<TableHead> 
<TableRow>
                          
						
                      </TableRow></TableHead>
					  
					   <EnhancedTableHead           
              order={this.state.order}
              orderBy={this.state.orderBy}
              
              onRequestSort={this.handleRequestSort}
              rowCount={this.state.Customerdata.length}
            />
                      <TableBody>
                     {stableSort(this.state.Customerdata, 
			getSorting(this.state.order,
			this.state.orderBy)).map((customer,index) => {  
           return (
 <React.Fragment>
 <TableRow key={index} >



<TableCell><a className="link" href={"#/Customerreportviewmore?cuid="+customer.cu_id+""}>{customer.cu_name}</a></TableCell>
<TableCell><a className="link" href={"#/Customerreportviewmore?cuid="+customer.cu_id+""}>{customer.date}</a></TableCell>
<TableCell><a className="link" href={"#/Customerreportviewmore?cuid="+customer.cu_id+""}>{customer.venue}</a></TableCell>
<TableCell><a className="link" href={"#/Customerreportviewmore?cuid="+customer.cu_id+""}>{customer.venuerating}</a></TableCell>
<TableCell><a className="link" href={"#/Customerreportviewmore?cuid="+customer.cu_id+""}>{customer.decorrating}</a></TableCell>
<TableCell><a className="link" href={"#/Customerreportviewmore?cuid="+customer.cu_id+""}>{customer.caterating}</a></TableCell>
<TableCell><a className="link" href={"#/Customerreportviewmore?cuid="+customer.cu_id+""}>{customer.photorating}</a></TableCell>
<TableCell><a className="link" href={"#/Customerreportviewmore?cuid="+customer.cu_id+""}>{customer.makeuprating}</a></TableCell>
 



<TableCell align="left"></TableCell>

</TableRow>

 </React.Fragment>	
);
					})}
					
					{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colspan="4">No Records</TableCell>
					</TableRow>
					) }
					
                      </TableBody>


</Table>
  </TableContainer> 
  <TablePagination  
        rowsPerPageOptions={[50,100,150,200,250]}
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onPageChange={this.handleChangePage}  
        onRowsPerPageChange={this.handleChangeRowsPerPage}   
      />   
  </Paper>
</div>
</div></div>
 </React.Fragment> 


 
	
	
		
		);
	}	
		
	
	   

}