import { BrowserRouter as Router, Route, Routes,NavLink,Outlet } from 'react-router-dom';
import React ,{Component} from 'react';
import ReactDOM from "react-dom";
import Serverurl from './Apiurl';
import axios from 'axios';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import TableSortLabel from '@mui/material/TableSortLabel';
import { makeStyles } from '@mui/styles';  

import { Navigate  } from 'react-router-dom';


import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

import Grid from '@mui/material/Grid';
import DateFnsUtils from '@date-io/date-fns';
import Moment from 'react-moment';
import moment from 'moment';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,DatePicker, 
  KeyboardDatePicker,
  DateTimePicker
} from '@material-ui/pickers';

import { addDays } from "date-fns";
import ReactToPrint from 'react-to-print';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';



const headCells = [

  { id: 'feedback_type', numeric: false, label: 'Type' },
  { id: 'fb_communication', numeric: false, label: 'Communication' },
  { id: 'fb_executiontime', numeric: false, label: 'Execition Time' },
  { id: 'fb_quality', numeric: false, label: 'Quality' },
  { id: 'fb_outout', numeric: false, label: 'Output' },
  { id: 'fb_valueformoney', numeric: false, label: 'Value for Money' },
  { id: 'fb_overallexperience', numeric: false, label: 'Overall Experience' },
 
  { id: '',  label: '' },
  ]
 function stableSort(array, cmp) {
	  const stabilizedThis = array.map((el, index) => [el, index]);
	  stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	  });
	  return stabilizedThis.map(el => el[0]);
}
function desc(a, b, orderBy) {
	  if (b[orderBy] < a[orderBy]) {
		return -1;
	  }
	  if (b[orderBy] > a[orderBy]) {
		return 1;
	  }
	  return 0;
}
function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
 
function EnhancedTableHead(props) {
  const { classes,  order, orderBy,  rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  };

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 
 icon: {
   verticalalign:'middle',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});
export default class Vendorreport extends Component {
	
	
			constructor(props) {
    super(props);
	
	
	
	
	var date = addDays(moment().toDate(),-30);
   var month="",  month1="",date1="";
  if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate())		 
	 }else{date1=(date.getDate() )}
	 
	 
	    var strtodate = addDays(moment().toDate(),0);
   var strmonth="",  month1="",strdate1="";
  if((strtodate.getMonth() + 1)<10)
	 {
		strmonth="0"+ (strtodate.getMonth() + 1)		 
	 }else{strmonth=(strtodate.getMonth() + 1)}
	  if((strtodate.getDate() )<10)
	 {
		strdate1="0"+ (strtodate.getDate())		 
	 }else{strdate1=(strtodate.getDate() )}
	
	
	
	
	
	
   
      this.state = {ve_type:'',selectedType:'',redirect:false,fb_cu_id:'',errormessage:'',erropen:false,open:false,
      Vendordata:[],cuid:'',name:'',page: 0 ,prevpage:0,totaldata:0,rowsPerPage:50,rowsPaging:[],
	  order:'desc' ,orderby:'fb_ve_id',fb_ve_id:'',title:'',type:'',search:'',fdate:'',tdate:'',venue:'',service:'',
	   createddate:'',id:'', exportData:[],vendorarray:[],vendor:'',rolelabel:'Select Vendor',

        date: addDays(moment().toDate(),0),
	fdate:addDays(moment().toDate(),-30),tdate:addDays(moment().toDate(),0),
fdate1:date.getFullYear() + '-' + month + '-' +date1,tdate1:strtodate.getFullYear() + '-' + strmonth + '-' +strdate1,types:'',

	   
			}
      
	  };
	  
	  
	  	 
handfrChange = (date) => {
	 var month="",date1="";
	
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)}
	 
	 
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate() )		 
	 }else{date1=(date.getDate())}
     this.setState({ fdate:date,
fdate1:date.getFullYear() + '-' + month + '-' +date1
	 })
	
	
  }	 
	 
	
handtoChange = (date) => {
	  var month="",date1="";
	 
	 if((date.getMonth() + 1)<10)
	 {
		month="0"+ (date.getMonth() + 1)		 
	 }else{month=(date.getMonth() + 1)} 
	  if((date.getDate() )<10)
	 {
		date1="0"+ (date.getDate() )		 
	 }else{date1=(date.getDate())}
	  var strtotime=this.state.fdate1.split(' ');
	 
	if(strtotime[0]>date.getFullYear() + '-' + month + '-' +date.getDate())
	{
		 var strtoday=this.state.fdate1.split('-');
		 if(strtoday[0]>date.getFullYear() && strtoday[1]>month && strtoday[2]>date.getDate())
	{
	alert("Please Select To date greater than equal to")
	return false;
	}
	}
	 
     this.setState({ tdate:date,
tdate1:date.getFullYear() + '-' + month + '-' +date1
	 })
	
	
  }

	
myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;

    this.setState({ [nam]: val },()=>{
	if(nam==="types"){
		this.setState({selectedType:val},()=>{
			//alert(this.state.selectedType);
			  this.onloadvendorbind(); 
		});
	}
	
	})
      
    
}

  
handleSubmit= (event) => {
    
    event.preventDefault();
	//alert('')
  

	let invoice = this.state.invoice;
	
	let fdate = this.state.fdate1;
	let tdate = this.state.tdate1;
	
	
	
	this.apicall(this.state.page,this.state.rowsPerPage);
	
} 

 componentDidUpdate() {
		
  if(this.state.prevpage !== this.state.page) {
    this.setState({   prevpage: this.state.page});
  this.apicall(this.state.page, localStorage.getItem("page"));  
  }
}


componentDidMount() {
	
	if(localStorage.getItem("YSuserid")==null)
			{

				   this.setState({
					redirect: true,
					pagename:'Login'
				  })
			}
			
			document.title = "Yashaswi Silks - Vendor Report";
			
			
			  localStorage.setItem("page",this.state.rowsPerPage)
this.onloadvendorbind();
}

onloadvendorbind(){

    axios.get(Serverurl+'ajaxvendor.php?action=getall&ve_type='+this.state.selectedType)
	
    
      .then((response) => {    
     
      this.setState({  vendorarray: response.data,  });
      })   
    
      .then(data => {
        let roleFromApi = this.state.vendorarray.map(vendor => {
                            
          return {value: vendor.ve_id, display:vendor.ve_name}
        });
      
		
        this.setState({
          vendorarray: [{value: '', display: 'Select Vendor'}].concat(roleFromApi)
        });
      })


} 
	

apicall(page,rowsPerPage){
	
	
	//this.setState({btndisabled:true,submitbtn:"Processing..."})	
	
	
		
	axios.get(Serverurl+'ajaxdisinctvendorfeedbackreport.php?&fb_ve_id='+this.state.fb_ve_id+'&fb_cu_id='+this.state.fb_cu_id+'&fromdate='+this.state.fdate1+'&todate='+this.state.tdate1+'&StartIndex='+page+'&PageSize='+rowsPerPage+'&venue='+this.state.venue+'&type='+this.state.types)
  	
	
  .then(resp => {  
		
    if(resp.data.length>0){
		let copy=[50, 100, 150, 200, 250];
			this.setState({  
			  Vendordata: resp.data,  			  
			   totaldata:parseInt(resp.data[0].count),rowsPaging:copy,
			   
			   vendorname:resp.data[0].ve_name,	
	cuname:resp.data[0].cu_name, 
	btndisabled:false,submitbtn:"Search"
	
			});
			
			
	
			 
     }else{this.setState({  
	 Vendordata:[],totaldata:0,
	 btndisabled:false,submitbtn:"Search"});
	 }
  
    });
	
		
	
	
	
}


handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};

handleChangePage = (event, newPage) => {  

    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:this.state.rowsPerPage
    },()=>{ this.apicall(this.state.page,this.state.rowsPerPage);  });
   
 
    
  }; 
  
  handleChangeRowsPerPage = event => {  
  
    let pageval = event.target.value;
  

   
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    },()=>{ this.apicall(this.state.page,this.state.rowsPerPage); }); 
	
	
  }; 
handleSubmit = (event) => {
    event.preventDefault();

    let fdate1 = this.state.fdate1;
    let tdate1 = this.state.tdate1;
    let fb_ve_id = this.state.fb_ve_id;
    let fb_cu_id = this.state.fb_cu_id;
    let venue = this.state.venue;

  

    if (this.state.types === "") {
        this.setState({ errormessage: "Please select Type", erropen: true });
        window.scrollTo({ top: 0 });
        return;
    }
	
	  if (this.state.fb_ve_id === "") {
        this.setState({ errormessage: "Please select Vendor", erropen: true });
        window.scrollTo({ top: 0 });
        return;
    }

    if (this.state.fb_ve_id !== "" && this.state.types !== "") {
		this.apicall(this.state.page, this.state.rowsPerPage);
		 
       
        
    }
	this.setState({ errormessage: "", erropen: false });
}

 handleErrorclose=(event)=>{
	  
		this.setState({erropen: false});  
	 } 
	
render() {
				
				if (this.state.redirect === true) {
			  return <Navigate to={this.state.pagename} />
			}
			
		return (
		
					
			<React.Fragment>
			
			<div className="containermain">
		<div className="containerbg">
   
		
			<div className="pageheader">
				<h1>Vendor Report</h1>
			</div>

		<div className="searchmsters">
			<p> 
				<Collapse in={this.state.erropen}>
				<Alert
				  action={
					<IconButton
					  aria-label="close"
					  color="inherit"
					  size="small"
					  onClick={() => {
					   this.handleErrorclose();
					  }}
					>
					  <CloseIcon fontSize="inherit" />
					</IconButton>
				  } severity="error"
				>
				{this.state.errormessage}
				</Alert>
			  </Collapse>
			</p>
 
  <ul>
  
  <li><p>From :</p>
		 
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
				  
	     <Grid container justify="space-around">
					  <DatePicker fullWidth
						value={this.state.fdate || addDays(moment().toDate(),1)}
						maxDate={addDays(moment().toDate(),0)}
						onChange={this.handfrChange}
							format="dd/MM/yyyy"
						autoOk name="fdate"
						ampm={false}
						variant="inline"
		inputVariant="outlined"
						id="borderdate"
					  />
					   </Grid>
    </MuiPickersUtilsProvider>
		</li>
 
 <li><p>To :</p>
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
					  <Grid container justify="space-around">
					  <DatePicker fullWidth
						value={this.state.tdate || addDays(moment().toDate(),1)}
						maxDate={addDays(moment().toDate(),0)}
						onChange={this.handtoChange}
							format="dd/MM/yyyy"
						autoOk name="tdate"
						ampm={false}
						variant="inline"
		inputVariant="outlined"
						id="borderdate"
					  />
					   </Grid>
					</MuiPickersUtilsProvider>
	
		</li>
		<li className="lialignment">
		
		<p>Venue</p>
		
		<p>
		  <FormControl  className="stadrp">			   
		<Select
		variant="outlined"
		native
		value={this.state.venue}
		onChange={this.myChangeHandler}
		InputLabelProps={{ shrink: true }} 
		name='venue'
		id='venue' 
		InputLabelProps={{
		shrink: true,
		}}
		>
		<option value={0}>Select</option>
		<option value="1">Yashaswi</option>
		<option value="2">The New Castle</option>	
		<option value="3">Spectra</option>		
		</Select>
		</FormControl></p>
		 		
		</li> 
		
		<li><p> Type</p><p>
		  <FormControl  className="stadrp">			   
		<Select
		variant="outlined"
		native
		value={this.state.types}
		onChange={this.myChangeHandler}
		InputLabelProps={{ shrink: true }} 
		name='types'
		id='types' 
		InputLabelProps={{
		shrink: true,
		}}
		>
		<option value="0">Select</option>
		<option value="1">Decor</option>
		<option value="2">Catering</option>	
		<option value="3">Photography</option>
<option value="4">Makeup Artiest</option>		
		</Select>
		</FormControl></p></li>
		
		
		<li><p> Vendor</p>
		<p><FormControl  className="stadrp">
         
        <Select
         native  fullWidth		 
		 variant="outlined"
            margin="normal"
		  defaultValue={{ label: this.state.rolelabel, value: this.state.vendor }}
          onChange={this.myChangeHandler}
          id="fb_ve_id"
		   value={this.state.fb_ve_id}
		  name="fb_ve_id"
		
		
        >
		{this.state.vendorarray.map(vendor => (
            <option  
            
              value={vendor.value}
            >
              {vendor.display}
            </option  >
          ))}
		
        </Select></FormControl>
		</p></li>
		
		
		<li>

					<p className="searchbtn">
						<Button variant="Text" onClick={this.handleSubmit} className="searchbttn">
							Search
						</Button>&nbsp;&nbsp;

					  
		  
				  
			 <ReactHTMLTableToExcel
							id="test-table-xls-button"
							className="expbtn"
							table="vendorreport"
							filename="Vendor Report"
							sheet="Vendor Report"
							buttonText="EXPORT"/>&nbsp;&nbsp;
<ReactToPrint
						trigger={() => <p id="print-barcode" className="printreport"> PRINT </p>}
						content={() => this.componentRef}/>
							
				   </p></li>
				   </ul>
		</div>
				
		   
			 
		
				
		
	
		
		
		
	
 <div ref={el => (this.componentRef = el)} style={{"margin-left":"20px","fontFamily":"Arial","margin-top":"10px","fontSize":"15px"}} >

<Paper className={useStyles.root}>
<TableContainer className={useStyles.container}>  
<Table stickyHeader aria-label="sticky table" id="vendorreport">  

			{this.state.Vendordata.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row,index) => {  
               		  
			   return ( 
                 <React.Fragment>
				 
		<TableRow colSpan={2} >   
               
			
            
	<TableCell>			
		
<div className="reportmain">
<div className="repfromdetails">

<p>Vendor Name : <b>{row.ve_name}</b></p>
<p>Venue : <b>{row.venue}</b></p>
<p>Customer Name :<b> {row.cu_name}</b></p>

</div>

	
	<div className="reportrt">
	
	<p>Feedback Given By :<b> {row.cu_feedbackgivenby}</b></p>
<p>Feedback Taken By : <b>{row.st_name}</b></p>
</div>
<div clssName="clear"></div>
	
	
	</div>
</TableCell>


			   
		   </TableRow> 
			  
			
              
  <TableRow className="expandable" ref="expanderBody" key={"tr-expander"}> 
      <TableCell className="uk-background-muted" colSpan={0}> 
      <TableContainer className={useStyles.container}>  
        <Table stickyHeader aria-label="sticky table" className="venfeedback"> 
        
		<TableBody>	
		
        
            <TableRow>   
			 
          
			   <TableCell ><b>Type</b></TableCell>
			   <TableCell ><b>Package</b></TableCell>
			   <TableCell ><b>Communication</b></TableCell>			   
               <TableCell ><b>Execution Time</b></TableCell>  
               <TableCell ><b>Quality</b></TableCell>
             	   
               <TableCell ><b>Output</b></TableCell>  
               <TableCell ><b>Value for Money</b></TableCell> 
               <TableCell ><b>Overall Experience</b></TableCell> 
				<TableCell ><b>Other Feedback</b></TableCell> 
              
               
            </TableRow>  
        
      {this.state.Vendordata[index].vendorfeedback.map((roweach) => {


              return ( 
     
        
            <TableRow> 
<TableCell>{roweach.feedback_type}</TableCell>
<TableCell>{roweach.fb_pa_id}</TableCell>
<TableCell>{roweach.fbcommunication}</TableCell>
<TableCell>{roweach.fbexecutiontime}</TableCell>
<TableCell>{roweach.fbquality}</TableCell>
<TableCell>{roweach.fboutput}</TableCell>
<TableCell>{roweach.fbvalueformoney}</TableCell>
<TableCell>{roweach.fboverallexperience}</TableCell>	 
 <TableCell>{roweach.fb_otherfeedback}</TableCell>       
        </TableRow>
		
		
		
		

        
              )})}
			  
			
	
			  
			  
			  
			  
             </TableBody>
              </Table> 
			  
			  
           </TableContainer>
                 </TableCell>
           </TableRow> 
		   
		   
              </React.Fragment>   
              );  
           })}  
			
		
		{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colspan="2">No Records</TableCell>
					</TableRow>
					) }
			
     
        

  
  </Table>
  </TableContainer> 
  
    <TablePagination  
        rowsPerPageOptions={[50, 100, 150, 200, 250]}
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onPageChange={this.handleChangePage}  
        onRowsPerPageChange={this.handleChangeRowsPerPage}   
      /> 
   
    </Paper>
</div>
</div></div>
 </React.Fragment> 

	
	 

	);
 }
} 

