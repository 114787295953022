import { BrowserRouter as Router, Route, Routes,NavLink,Outlet } from 'react-router-dom';
import React ,{Component} from 'react';
import ReactDOM from "react-dom";
import logo from './images/weblogo.png';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import CopyAllOutlinedIcon from '@mui/icons-material/CopyAllOutlined';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import GroupIcon from '@mui/icons-material/Group';
import InventoryIcon from '@mui/icons-material/Inventory';
import ReviewsIcon from '@mui/icons-material/Reviews';
import PersonIcon from '@mui/icons-material/Person';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { styled, useTheme } from '@mui/material/styles';
import Displaymenu from './Displaymenu';
import Link from '@mui/material/Link';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import OpenMenuimg from  './images/menu-icon.png';
import ListItem from '@mui/material/ListItem';
import { Slot } from 'react-page-layout';
import ListItemText from '@mui/material/ListItemText';
const createHistory = require("history").createBrowserHistory;
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);



export default class MiniDrawer extends Component {	
	

  constructor(props) {
    super(props)
    this.state = {

        open:false,reason:'',anchorEl:null,menuopen:null,submenuopen:false,
		network:1,name:'',redirect:false,pagename:'',mainmenu:0
    }
    
}

componentDidMount() {

if(localStorage.getItem("YSuserid")!=null)
 {
	 //this.usernameapi();
 }

 
}	


 handledownclick = (e) => {
	 this.setState({ open:true,submenuopen:true });
 }
 
handleDrawerOpen(val){
        this.setState({ id: val });  
 // alert(val);
       

if(val===0)
{
	this.setState({ open:!this.state.open,submenuopen:true });
}
else{
	this.setState({ open:true,submenuopen:true });
	
}


      }
	  
	/*  
handleDrawerOpen = (e) => {



  this.setState({ open:!this.state.open,submenuopen:true });

//const {onToggle, setCollapsibleElement, progress } = this.state;



};*/
handleDrawerClose = (e) => {
   
  this.setState({ open:!this.state.open,submenuopen:true });
//const {onToggle, setCollapsibleElement, progress } = this.state;


};

 handleClick = (e) => {
   
  this.setState({ anchorEl:e.currentTarget ,menuopen:true});
};

 handleClose = (e) => {
   
  this.setState({ anchorEl:null ,menuopen:false});
};
  

  
  
 userlogout =(anchorEl,menuopen) => (event) => {
	
	
	if(menuopen==true){
	
	let myItem = localStorage.getItem('YSusername');
	let myItem1 = localStorage.getItem('YSpassword');
localStorage.clear();
localStorage.setItem('YSusername',myItem);
  localStorage.setItem('YSpassword',myItem1);
   //location.href = "/Signin/"
	let history = createHistory();
        history.push("#");
        let pathUrl = window.location.href;
        window.location.href = pathUrl;   
	
	}
  };
	
render() {
const {onToggle, setCollapsibleElement, progress } = this.state;

  return (
   <React.Fragment> 
  hello
  
   <Box sx={{ display: 'flex' }}>
      <CssBaseline />
	   <Drawer variant="permanent" open={this.state.open}>
	 <div className="mainmenus">			
		
		<DrawerHeader>
	
	 { this.state.open!==true && (	
 <IconButton onClick={()=>{this.handleDrawerOpen(0)}} style={{"margin-left":"10px"}} >
 <img src={OpenMenuimg}     />  
    
    </IconButton>
	
	 )} 
				 
			 { this.state.open===true && (
			 
        <IconButton onClick={this.handleDrawerClose}>
         <ChevronLeftIcon />
		 
		 
          </IconButton>
			 )}
			
		 
	</DrawerHeader>
	
	 <Divider />
       
      
	  
	  
<List> 	
 {(localStorage.getItem("YSrole") === "1" || localStorage.getItem("YSrole") === "2") && (
	<ListItem>
<a href={"#/User"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="User">
 <IconButton aria-label="User">
 <GroupIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="User" />
 </ListItem></a>
 	</ListItem>
 
 )} 

  
  {(localStorage.getItem("YSrole") === "1" || localStorage.getItem("YSrole") === "2") && ( 
		<ListItem>
	<a href={"#/Staff"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Staff">
 <IconButton aria-label="Staff">
 <AccountBoxIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Staff" />
          </ListItem></a>	  
		  
		</ListItem>	  

 )}

 
   
   {(localStorage.getItem("YSrole") === "1" || localStorage.getItem("YSrole") === "2") && (
	<ListItem>
<a href={"#/Vendor"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Vendor">
 <IconButton aria-label="Vendor">
 <PersonIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Vendor" />
          </ListItem></a>
	</ListItem>
   )}
   
   {(localStorage.getItem("YSrole") === "1" || localStorage.getItem("YSrole") === "2") && (
	<ListItem>
<a href={"#/Feedback"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Feedback">
 <IconButton aria-label="Feedback">
 <ReviewsIcon /> 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Feedback" />
          </ListItem></a>	
	</ListItem>
   )}
   
   {(localStorage.getItem("YSrole") === "1" || localStorage.getItem("YSrole") === "2") && (
	<ListItem>
<a href={"#/Customermonthlyreport"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Monthly Report">
 <IconButton aria-label="Monthly Report">
 <ReviewsIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Monthly Report" />
          </ListItem></a>
	</ListItem>
   )}
   
   {(localStorage.getItem("YSrole") === "1" || localStorage.getItem("YSrole") === "2") && (
   	<ListItem>
<a href={"#/Vendorreport"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title=" Vendor Report">
 <IconButton aria-label=" Vendor Report">
 <ReviewsIcon />
 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary=" Vendor Report" />
          </ListItem></a>
	</ListItem>
   )}
   
   
    {localStorage.getItem("YSrole") === "3" && (
  	<ListItem>
  <a href={"#/Feedback"}> <ListItem button >
 <ListItemIcon>
 <Tooltip title="Feedback">
 <IconButton aria-label="Feedback">
 <ReviewsIcon /> 
    </IconButton>
 </Tooltip>
   </ListItemIcon>
   <ListItemText primary="Feedback" />
          </ListItem></a>	
  	</ListItem>
  )}
 
</List> 
 
  

        <Divider />
       
	</div>
		  
	      </Drawer>
     
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
       <div className="masthead">
	   
	    <div className="mastheadleft">
	   <img src={logo} />
	   </div>
	   
	   
	    <div className="mastheadright">
	   <p className="welcometext">Welcome</p>
	  
	   
	   <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        
       
          <div  className="submenus" onClick={this.handleClick} size="small" sx={{ ml: 2 }}>
        <p className="pointer"><b>{localStorage.getItem("Ysname")}</b> <Settings size="small"/></p>
          </div>
       
      </Box>
 <Menu
        anchorEl={this.state.anchorEl}
        open={this.state.menuopen}
        onClose={this.handleClose}
        onClick={this.handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        
       
        <div className="popupwindow">    
        <MenuItem>
         
		  <div className="setingdiv">
		    <a href='#/Changepassword' className="colorlink"> 
			 <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
            Change Password
          </a>
		  </div>
        </MenuItem>
		
		 	
		
        <MenuItem>
          
		 
		    <div className="setingdiv">
			<a href='#/Logout'>
		   <ListItem button>
		   <ListItemIcon >
            <Logout fontSize="small" />
          </ListItemIcon>
            Logout
           </ListItem>
		   </a>
		   </div>
		 
		 
        </MenuItem>
		 </div>
      </Menu>
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   
	   </div>
	   
	   <div className="clear"></div>
	   
	   </div>
	   
	   
	   
	  
       <React.Fragment> 

<div className="contentheader">
		
			<h1> </h1>
</div>
								<div className="contentmain">
								  <Outlet />
								</div>
							






			

				


</React.Fragment>				
	



      </Box>
    </Box>
  
  </React.Fragment>  
  );
}
}
